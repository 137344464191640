

// Form Control
.form-control{
    height: 48px;
    padding: 0.375rem 0.75rem;
    background: transparent;
    border-color: $border-color;
    color: $black;
    @include border-radius($border-radius);
    @include placeholder {
        color:$gray;
    }
    &.form-control-lg{
        height: 60px;
    }
    &.form-control-sm{
        height: 36px;
    }
    &.square-input{
        border-radius: 0;    
    }
    &.line-input{
        padding: size(20) 0;
        border-width: 0 0 1px 0;
        border-radius: 0;
        background: transparent;        
    }
    &.focus, &:focus {
        color: $black;
        box-shadow: none;
        border-color: $primary;
        @include placeholder {
            color:$primary;
        }
    }
    &[readonly]{
        color: $gray;
    }    
}
textarea{
    resize: none;
    &.form-control {
        padding: size(12) size(16);
    }
}
.has-danger{
    color: $danger;
    .form-control{
        border-color:$danger;
    }
}
/*==Custom Radio & Checkbox ==*/
.custom-checkbox{
    &.vertical{
        label{
            display: block;
        }
    }
    label{
        margin:0 10px 10px 0;
        padding: 0 0 0 26px;
        cursor: pointer;
        position: relative;
        display:inline-block
    }
    input[type="checkbox"]{
        display: none;
        + span {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0;
            background: $white;
            border: 1px solid $border-color;
            border-radius: $border-radius; 
        }
        &:checked{
            + span {
                border: 1px solid $primary;
                background: $primary;
                @include css3-prefix(transition, all 0.3s);
            }
            ~ span:after{
                top: 50%;
                left: 50%;
                margin-left: -2px;
                margin-top: -7px;
                width: 5px;
                height: 10px;
                border-width: 0 1px 1px 0 !important;
                display: block;
                border: solid $white;
                @include css3-prefix(transform, rotate(45deg));
            }
        }
        ~ span:after{
            display: none;
            position: absolute;
            content: "";
        }
        &:disabled{
            + span {
                opacity: 0.5;
                cursor: not-allowed;
                + span {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }

}
.custom-radio{
    &.vertical{
        label{
            display: block;    
        }
    }
    label{
        margin:0 10px 10px 0;
        padding: 0 0 0 26px;
        cursor: pointer;
        position: relative;
        display:inline-block
    }
    input[type="radio"]{
        display: none;
        + span{
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0;
            background: $white;
            border: 1px solid $border-color;
            border-radius: 50%;
        }
        ~ span:after {
            display: none;
            position: absolute;
            content: "";
        }
        &:disabled{
            + span {
                opacity: 0.5;
                cursor: not-allowed;
                + span {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
        &:checked{
            + span {
                background: $primary;
                border: 1px solid $primary;
                @include css3-prefix(transition, all 0.3s);
            }
            ~ span:after{
                top: 50%;
                left: 50%;
                margin-left: -5px;
                margin-top: -5px;
                width: 10px;
                height: 10px;
                display: block;
                background: $white;
                @include border-radius(50%);
            }
        }
    }
}
.ie8 .custom-checkbox input[type="checkbox"],
.ie8 .custom-radio input[type="radio"] {
  display: inline-block;
}
.ie8 .custom-checkbox span,
.ie8 .custom-radio span {
  display: none;
}

.otp-input {
    input {
        padding: 5px 5px;
        border: solid 1px $border-color;
        color: $black;
        min-width: 32px;
        margin: 0 5px;
    }
}