
/* =========== IMAGE PATH VARIABLE ============= */
$assetPath : "/assets/images";
/* Usage of bacground image */
//background: url(#{$assetPath}/background.jpg) repeat-x fixed 0 0;


// Color system
$white:     	#ffffff;
$black:	      #000000;	
$light-black:	      #1A202C;	
$dark:	      #718096;	
$primary: 	  #001CD7;
$btn-primary: #011DD5;
$purple: #5851FF;
$secondary:	  #cbd5df;
$success:   	#2F855A;
$success-light:   	#4FD1C5;
$success-lightest:   	#E6FFFA;
$success-dark:   	#2C7A7B;
$green:   	#6FCF97;
$info:      	#7F9CF5;
$info-dark:      	#4C51BF;
$warning:   	#F2C94C;
$warning-dark:   	 #ECC94B;
$warning-light:   	#FBD38D;
$danger:        #E53E3E;
$danger-dark:      #C53030;
$red:      #EB5757;
$light:     	#fafafa;
$orange:     	#F2994A;
$gray: 		    #CBD5E0;
$dark-gray: 		    #0E0E17;
$light-gray: #EDF2F7;
$gray-border:   $light-gray;
$danger-light: #FEB2B2;
// Body
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $black;

// Links
// Style anchor elements.
$link-color:#0015A4;
$link-decoration:none;
$link-hover-color:darken($primary, 50%);
$link-hover-decoration:underline;

// Font
$font-family-base: 'Poppins';
$font-heading: 'Poppins';
$font-size-base: 1rem; // Assumes the browser default, typically `16px` 
$font-custom:16; 
$font-size-lg: $font-size-base * 1.25; //20px
$font-size-sm: $font-size-base * .875; //14px
$font-lighter: lighter;
$font-light: 300;
$font-regular:400;
$font-medium:500;
$font-semibold: 600;
$font-bold: 700;
$font-bolder: bolder;
$line-height-base: 1.25;

// Style p element.
$paragraph-margin-bottom:   1rem;

// Media BreakPoint
$breakpoint-xxl: 1440px;
$breakpoint-xl: 1200px;
$breakpoint-lg: 992px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;
$breakpoint-xs: 414px;

// Components
// Define common padding and border radius sizes and more.
$border-width:1px;
$border-color:#ced4da;

$border-radius:.25rem;
$border-radius-lg:.3rem;
$border-radius-sm:.2rem;

$rounded-pill:50rem;

$box-shadow-sm:0 .125rem .25rem rgba($black, .075);
$box-shadow:0 .5rem 1rem rgba($black, .15);
$box-shadow-lg:0 1rem 3rem rgba($black, .175);
$box-shadow-0:none;
$box-shadow-sm-reverse:0 -.125rem .25rem rgba($black, .075);

$caret-width:.3em;
$caret-vertical-align:$caret-width * .85;
$caret-spacing:$caret-width * .85;

$transition-base:all .2s ease-in-out;
$transition-fade:opacity .15s linear;
$transition-collapse:height .35s ease;

// Buttons
$btn-padding-y:                .4537rem;
$btn-padding-x:               .75rem;
$btn-font-family:             $font-family-base;
$btn-font-size:               .875rem;
$btn-line-height:              1.5rem;
$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            .1875rem;
$btn-padding-x-sm:            0.5rem;
$btn-font-size-sm:            .6875rem ;
$btn-line-height-sm:            1rem;

$btn-padding-y-lg:            1rem;
$btn-padding-x-lg:            1.25rem;
$btn-font-size-lg:            $font-size-lg;

// Forms
$label-margin-bottom:         .5rem;
$input-padding-y:             1rem;
$input-padding-x:             1rem;
$input-font-family:           $font-family-base;
$input-font-size:             $font-size-base;
$input-font-weight:           $font-regular;

$input-padding-y-sm:          0.5rem;
$input-padding-x-sm:          0.5rem;
$input-font-size-sm:          $font-size-sm;

$input-padding-y-lg:          1.25rem;
$input-padding-x-lg:          1.25rem;
$input-font-size-lg:          $font-size-lg;

//Gradiant Style
$fallback:$primary;
$from:$primary;
$to:darken($primary,10);
$direction:225deg;

// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                true !default;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
  ),
  $spacers
);

//Custom spacers
// $spacer: 1rem;
// $spacers: ( 0:0);
// @for $i from 1 through 10 {
//   $spacers: map-merge($spacers, (
//   $i: ($i * $spacer/4)
//   ));
// }