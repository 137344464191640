// MEDIA BREAKPOINTS
@mixin media($breakpoint, $point) {
  @if $breakpoint == down {
    @media (max-width: #{$point - 0.2}) { @content; }
  }
  @else if $breakpoint == up {
    @media (min-width: $point) { @content; }
  }
}
// How to use media mixin demo
//  body {
//    background:$primary;
//    @include media(down, $breakpoint-xl) {
//      background: $secondary;
//    }
//  }
//  body {
//    background: $secondary;
//    @include media(up, $breakpoint-xl) {
//      background: $primary;
//    }
// }

// CALC FUNCTION RULE
@mixin calc($property, $expression) { 
  #{$property}: calc(#{$expression});
}
// Usage Calc Function demo 
// div {
//    @include calc( width, '100% - 2px');
// }

// PREFIX RULE 
@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}
// Usage Prefix 
// div {
//    @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
// }
  
// POSITION 
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// TEXT ELLIPSIS
@mixin text-ellipsis {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

// BORDER RADIUS 
@mixin border-radius($border-radius) {
  border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
}

// Button sizes
@mixin button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $font-semibold, $box-shadow) {
  padding: $btn-padding-y $btn-padding-x;
  font-size: $font-size-base;
  line-height:$btn-line-height;
  font-weight: $font-semibold;
  box-shadow: $box-shadow;
}

// PLACEHOLDER RULE
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

// Gradients
@mixin x-linear-gradient($direction, $fallback, $from, $to) {
  $directions: helper-gradient-angle($direction);
  // Provide a fallback-color
  background-color: $fallback;
  // Cross-browser linear-gradients
  background-image: -webkit-gradient(linear,$directions, from($from), to($to)); // Android 2.1-3.0
  background-image: -webkit-linear-gradient($directions, $from, $to);
  background-image:linear-gradient($direction, $from, $to);
}

// condition: @if and @else square circle 
@mixin avatar($size, $circle: false) {
  width: $size;
  height: $size;
  @if $circle {
    border-radius: $size / 2;
  }
}
// How to use : 
// .square-av {
//   @include avatar(100px, $circle: false);
//   background: $primary;
// }
// .circle-av {
//   @include avatar(100px, $circle: true);
//   background: $primary;
// }



/*Large devices (desktops, less than 1200px)*/
@mixin mx-1199 {
  @media only screen and (max-width: 1199.98px) {
    @content;
  }
}
/* Medium devices (tablets, less than 1024px) */
@mixin mx-1024{
  @media only screen and (max-width:1024px) {
    @content;
  }
}
/* Medium devices (desktop, greter than 1024px) */
@mixin mi-1025{
  @media only screen and (min-width:1025px) {
    @content;
  }
}
/* Medium devices (tablets, less than 992px) */
@mixin mx-991{
  @media only screen and (max-width:991.98px) {
    @content;
  }
}
/* Small devices (landscape phones, less than 768px)*/
 @mixin mx-767 {
  @media only screen and (max-width: 767.98px) {
    @content;
  }
}
/* LG devices min (more than 768px)*/
 @mixin  mi-768 {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
/* LG devices min (more than 992px)*/
 @mixin mi-992 {
  @media only screen and (min-width: 992px) {
    @content;
  }
}
/* Extra small devices (portrait phones, less than 560px) */
@mixin mx-560 {
  @media only screen and (max-width: 560px) {
    @content;
  }
}
/* Extra small devices (portrait phones, less than 575px) */
@mixin mx-575 {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
/* Extra small devices (portrait phones, less than 413px) */
@mixin mx-414 {
  @media only screen and (max-width: 414px) {
    @content;
  }
}
@mixin mx-410_min-320 {
  @media only screen and (max-width: 410px) and (min-width: 320px){
    @content;
  }
}
/* Extra small devices ( less than 991, more than 768px) */
@mixin mx-991_min-768 {
  @media (min-width:768px) and (max-width:991px){
    @content;
  }
}
/* Extra small devices (portrait phones, less than 375px) */
@mixin mx-375 {
  @media only screen and (max-width: 375px) {
    @content;
  }
}
/* Extra small devices (portrait phones, less than 320px) */
@mixin mx-320 {
  @media only screen and (max-width: 320px) {
    @content;
  }
}
@mixin mx-767_land {
  @media screen and (max-device-width: 767px) and (orientation: landscape) {
    @content;
  }
}