

/* poppins font */

  @font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins/Poppins-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins/Poppins-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins/Poppins-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins/Poppins-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins/Poppins-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins/Poppins-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins/Poppins-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

/* poppins font */



// icon
@font-face {
font-family: 'icomoon';
src:  url('../../fonts/icomoon.eot?gi3dmm');
src:  url('../../fonts/icomoon.eot?gi3dmm#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?gi3dmm') format('truetype'),
  url('../../fonts/icomoon.woff?gi3dmm') format('woff'),
  url('../../fonts/icomoon.svg?gi3dmm#icomoon') format('svg');
font-weight: normal;
font-style: normal;
font-display: block;
}

[class^="icon-"], [class*="icon-"] {
/* use !important to prevent issues with browser extensions that change fonts */
font-family: 'icomoon' !important;
speak: never;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;

/* Better Font Rendering =========== */
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
  .icon-intravenous-therapy:before {
    content: "\ed01";
  }
  .icon-wallet-alt:before {
    content: "\ed00";
  }
  .icon-truck-alt:before {
    content: "\ecfe";
  }
  .icon-plus-line:before {
    content: "\ecff";
  }
  .icon-order-notes:before {
    content: "\ecfa";
  }
  .icon-mug:before {
    content: "\ecfb";
  }
  .icon-scan:before {
    content: "\ecfc";
  }
  .icon-lock-log-alt:before {
    content: "\ecfd";
  }
  .icon-location-pin:before {
    content: "\ecf8";
  }
  .icon-user-profile:before {
    content: "\ecf9";
  }
  .icon-hospital-building:before {
    content: "\ecf6";
  }
  .icon-pill:before {
    content: "\ecf7";
  }
  .icon-bell-empty:before {
    content: "\ecf5";
  }
  .icon-stacked:before {
    content: "\ecf4";
  }
  
  .icon-reset:before {
    content: "\ecf3";
  }
  .icon-signout:before {
    content: "\ecf2";
  }
  .icon-abacus:before {
	content: "\e900";
  }
  .icon-accessible-icon-alt:before {
	content: "\e901";
  }
  .icon-adjust-alt:before {
	content: "\e902";
  }
  .icon-adjust-circle:before {
	content: "\e903";
  }
  .icon-adjust-half:before {
	content: "\e904";
  }
  .icon-adjust:before {
	content: "\e905";
  }
  .icon-airplay:before {
	content: "\e906";
  }
  .icon-align-alt:before {
	content: "\e907";
  }
  .icon-align-center-alt:before {
	content: "\e908";
  }
  .icon-align-center-h:before {
	content: "\e909";
  }
  .icon-align-center-justify:before {
	content: "\e90a";
  }
  .icon-align-center-v:before {
	content: "\e90b";
  }
  .icon-align-center:before {
	content: "\e90c";
  }
  .icon-align-justify:before {
	content: "\e90d";
  }
  .icon-align-left-justify:before {
	content: "\e90e";
  }
  .icon-align-left:before {
	content: "\e90f";
  }
  .icon-align-letter-right:before {
	content: "\e910";
  }
  .icon-align-right-justify:before {
	content: "\e911";
  }
  .icon-align-right:before {
	content: "\e912";
  }
  .icon-align:before {
	content: "\e913";
  }
  .icon-ambulance:before {
	content: "\e914";
  }
  .icon-analysis:before {
	content: "\e915";
  }
  .icon-analytics:before {
	content: "\e916";
  }
  .icon-anchor:before {
	content: "\e917";
  }
  .icon-android-phone-slash:before {
	content: "\e918";
  }
  .icon-angle-double-down:before {
	content: "\e919";
  }
  .icon-angle-double-left:before {
	content: "\e91a";
  }
  .icon-angle-double-right:before {
	content: "\e91b";
  }
  .icon-angle-double-up:before {
	content: "\e91c";
  }
  .icon-angle-down:before {
	content: "\e91d";
  }
  .icon-angle-left:before {
	content: "\e91e";
  }
  .icon-angle-right-b:before {
	content: "\e91f";
  }
  .icon-angle-right:before {
	content: "\e920";
  }
  .icon-angle-up:before {
	content: "\e921";
  }
  .icon-annoyed-alt:before {
	content: "\e922";
  }
  .icon-annoyed:before {
	content: "\e923";
  }
  .icon-apps:before {
	content: "\e924";
  }
  .icon-archive-alt:before {
	content: "\e925";
  }
  .icon-archive:before {
	content: "\e926";
  }
  .icon-arrow-break:before {
	content: "\e927";
  }
  .icon-arrow-circle-down:before {
	content: "\e928";
  }
  .icon-arrow-circle-left:before {
	content: "\e929";
  }
  .icon-arrow-circle-right:before {
	content: "\e92a";
  }
  .icon-arrow-circle-up:before {
	content: "\e92b";
  }
  .icon-arrow-compress-h:before {
	content: "\e92c";
  }
  .icon-arrow-down-left:before {
	content: "\e92d";
  }
  .icon-arrow-down-right:before {
	content: "\e92e";
  }
  .icon-arrow-down:before {
	content: "\e92f";
  }
  .icon-arrow-from-right:before {
	content: "\e930";
  }
  .icon-arrow-from-top:before {
	content: "\e931";
  }
  .icon-arrow-growth:before {
	content: "\e932";
  }
  .icon-arrow-left:before {
	content: "\e933";
  }
  .icon-arrow-random:before {
	content: "\e934";
  }
  .icon-arrow-resize-diagonal:before {
	content: "\e935";
  }
  .icon-arrow-right:before {
	content: "\e936";
  }
  .icon-arrow-to-bottom:before {
	content: "\e937";
  }
  .icon-arrow-to-right:before {
	content: "\e938";
  }
  .icon-arrow-up-left:before {
	content: "\e939";
  }
  .icon-arrow-up-right:before {
	content: "\e93a";
  }
  .icon-arrow-up:before {
	content: "\e93b";
  }
  .icon-arrows-h-alt:before {
	content: "\e93c";
  }
  .icon-arrows-h:before {
	content: "\e93d";
  }
  .icon-arrows-left-down:before {
	content: "\e93e";
  }
  .icon-arrows-maximize:before {
	content: "\e93f";
  }
  .icon-arrows-merge:before {
	content: "\e940";
  }
  .icon-arrows-resize-h:before {
	content: "\e941";
  }
  .icon-arrows-resize-v:before {
	content: "\e942";
  }
  .icon-arrows-resize:before {
	content: "\e943";
  }
  .icon-arrows-right-down:before {
	content: "\e944";
  }
  .icon-arrows-shrink-h:before {
	content: "\e945";
  }
  .icon-arrows-shrink-v:before {
	content: "\e946";
  }
  .icon-arrows-up-right:before {
	content: "\e947";
  }
  .icon-arrows-v-alt:before {
	content: "\e948";
  }
  .icon-arrows-v:before {
	content: "\e949";
  }
  .icon-at:before {
	content: "\e94a";
  }
  .icon-atm-card:before {
	content: "\e94b";
  }
  .icon-atom:before {
	content: "\e94c";
  }
  .icon-auto-flash:before {
	content: "\e94d";
  }
  .icon-award-alt:before {
	content: "\e94e";
  }
  .icon-award:before {
	content: "\e94f";
  }
  .icon-baby-carriage:before {
	content: "\e950";
  }
  .icon-backpack:before {
	content: "\e951";
  }
  .icon-backspace:before {
	content: "\e952";
  }
  .icon-backward:before {
	content: "\e953";
  }
  .icon-bag-alt:before {
	content: "\e954";
  }
  .icon-bag-slash:before {
	content: "\e955";
  }
  .icon-bag:before {
	content: "\e956";
  }
  .icon-ball:before {
	content: "\e957";
  }
  .icon-ban:before {
	content: "\e958";
  }
  .icon-bars:before {
	content: "\e959";
  }
  .icon-basketball-hoop:before {
	content: "\e95a";
  }
  .icon-basketball:before {
	content: "\e95b";
  }
  .icon-battery-bolt:before {
	content: "\e95c";
  }
  .icon-battery-empty:before {
	content: "\e95d";
  }
  .icon-bed-double:before {
	content: "\e95e";
  }
  .icon-bed:before {
	content: "\e95f";
  }
  .icon-bell-school:before {
	content: "\e960";
  }
  .icon-bell-slash:before {
	content: "\e961";
  }
  .icon-bell:before {
	content: "\e962";
  }
  .icon-bill:before {
	content: "\e963";
  }
  .icon-bitcoin-circle:before {
	content: "\e964";
  }
  .icon-bitcoin:before {
	content: "\e965";
  }
  .icon-bluetooth-b:before {
	content: "\e966";
  }
  .icon-bolt-alt:before {
	content: "\e967";
  }
  .icon-bolt-slash:before {
	content: "\e968";
  }
  .icon-bolt:before {
	content: "\e969";
  }
  .icon-book-alt:before {
	content: "\e96a";
  }
  .icon-book-medical:before {
	content: "\e96b";
  }
  .icon-book-open:before {
	content: "\e96c";
  }
  .icon-book-reader:before {
	content: "\e96d";
  }
  .icon-book:before {
	content: "\e96e";
  }
  .icon-bookmark-full:before {
	content: "\e96f";
  }
  .icon-bookmark:before {
	content: "\e970";
  }
  .icon-books:before {
	content: "\e971";
  }
  .icon-boombox:before {
	content: "\e972";
  }
  .icon-border-alt:before {
	content: "\e973";
  }
  .icon-border-bottom:before {
	content: "\e974";
  }
  .icon-border-clear:before {
	content: "\e975";
  }
  .icon-border-horizontal:before {
	content: "\e976";
  }
  .icon-border-inner:before {
	content: "\e977";
  }
  .icon-border-left:before {
	content: "\e978";
  }
  .icon-border-out:before {
	content: "\e979";
  }
  .icon-border-right:before {
	content: "\e97a";
  }
  .icon-border-top:before {
	content: "\e97b";
  }
  .icon-border-vertical:before {
	content: "\e97c";
  }
  .icon-box:before {
	content: "\e97d";
  }
  .icon-briefcase-alt:before {
	content: "\e97e";
  }
  .icon-briefcase:before {
	content: "\e97f";
  }
  .icon-bright:before {
	content: "\e980";
  }
  .icon-brightness-empty:before {
	content: "\e981";
  }
  .icon-brightness-half:before {
	content: "\e982";
  }
  .icon-brightness-low:before {
	content: "\e983";
  }
  .icon-brightness-minus:before {
	content: "\e984";
  }
  .icon-brightness-plus:before {
	content: "\e985";
  }
  .icon-brightness:before {
	content: "\e986";
  }
  .icon-bring-bottom:before {
	content: "\e987";
  }
  .icon-bring-front:before {
	content: "\e988";
  }
  .icon-brush-alt:before {
	content: "\e989";
  }
  .icon-bug:before {
	content: "\e98a";
  }
  .icon-building:before {
	content: "\e98b";
  }
  .icon-bullseye:before {
	content: "\e98c";
  }
  .icon-bus-alt:before {
	content: "\e98d";
  }
  .icon-bus-school:before {
	content: "\e98e";
  }
  .icon-bus:before {
	content: "\e98f";
  }
  .icon-calcualtor:before {
	content: "\e990";
  }
  .icon-calculator-alt:before {
	content: "\e991";
  }
  .icon-calendar-alt:before {
	content: "\e992";
  }
  .icon-calendar-slash:before {
	content: "\e993";
  }
  .icon-calender:before {
	content: "\e994";
  }
  .icon-calling:before {
	content: "\e995";
  }
  .icon-camera-plus:before {
	content: "\e996";
  }
  .icon-camera-slash:before {
	content: "\e997";
  }
  .icon-camera:before {
	content: "\e998";
  }
  .icon-cancel:before {
	content: "\e999";
  }
  .icon-capsule:before {
	content: "\e99a";
  }
  .icon-capture:before {
	content: "\e99b";
  }
  .icon-car-sideview:before {
	content: "\e99c";
  }
  .icon-car-slash:before {
	content: "\e99d";
  }
  .icon-car-wash:before {
	content: "\e99e";
  }
  .icon-car:before {
	content: "\e99f";
  }
  .icon-card-atm:before {
	content: "\e9a0";
  }
  .icon-caret-right:before {
	content: "\e9a1";
  }
  .icon-cart:before {
	content: "\e9a2";
  }
  .icon-cell:before {
	content: "\e9a3";
  }
  .icon-celsius:before {
	content: "\e9a4";
  }
  .icon-chart-bar-alt:before {
	content: "\e9a5";
  }
  .icon-chart-bar:before {
	content: "\e9a6";
  }
  .icon-chart-down:before {
	content: "\e9a7";
  }
  .icon-chart-growth-alt:before {
	content: "\e9a8";
  }
  .icon-chart-growth:before {
	content: "\e9a9";
  }
  .icon-chart-line:before {
	content: "\e9aa";
  }
  .icon-chart-pie-alt:before {
	content: "\e9ab";
  }
  .icon-chart-pie:before {
	content: "\e9ac";
  }
  .icon-chart:before {
	content: "\e9ad";
  }
  .icon-chat-bubble-user:before {
	content: "\e9ae";
  }
  .icon-chat-info:before {
	content: "\e9af";
  }
  .icon-chat:before {
	content: "\e9b0";
  }
  .icon-check-circle:before {
	content: "\e9b1";
  }
  .icon-check-square:before {
	content: "\e9b2";
  }
  .icon-check:before {
	content: "\e9b3";
  }
  .icon-circle-layer:before {
	content: "\e9b4";
  }
  .icon-circle:before {
	content: "\e9b5";
  }
  .icon-circuit:before {
	content: "\e9b6";
  }
  .icon-clapper-board:before {
	content: "\e9b7";
  }
  .icon-clipboard-alt:before {
	content: "\e9b8";
  }
  .icon-clipboard-blank:before {
	content: "\e9b9";
  }
  .icon-clipboard-notes:before {
	content: "\e9ba";
  }
  .icon-clipboard:before {
	content: "\e9bb";
  }
  .icon-clock-eight:before {
	content: "\e9bc";
  }
  .icon-clock-five:before {
	content: "\e9bd";
  }
  .icon-clock-nine:before {
	content: "\e9be";
  }
  .icon-clock-seven:before {
	content: "\e9bf";
  }
  .icon-clock-ten:before {
	content: "\e9c0";
  }
  .icon-clock-three:before {
	content: "\e9c1";
  }
  .icon-clock-two:before {
	content: "\e9c2";
  }
  .icon-clock:before {
	content: "\e9c3";
  }
  .icon-closed-captioning:before {
	content: "\e9c4";
  }
  .icon-cloud-block:before {
	content: "\e9c5";
  }
  .icon-cloud-bookmark:before {
	content: "\e9c6";
  }
  .icon-cloud-check:before {
	content: "\e9c7";
  }
  .icon-cloud-computing:before {
	content: "\e9c8";
  }
  .icon-cloud-data-connection:before {
	content: "\e9c9";
  }
  .icon-cloud-database-tree:before {
	content: "\e9ca";
  }
  .icon-cloud-download:before {
	content: "\e9cb";
  }
  .icon-cloud-drizzle:before {
	content: "\e9cc";
  }
  .icon-cloud-exclamation:before {
	content: "\e9cd";
  }
  .icon-cloud-hail:before {
	content: "\e9ce";
  }
  .icon-cloud-heart:before {
	content: "\e9cf";
  }
  .icon-cloud-info:before {
	content: "\e9d0";
  }
  .icon-cloud-lock:before {
	content: "\e9d1";
  }
  .icon-cloud-meatball:before {
	content: "\e9d2";
  }
  .icon-cloud-moon-hail:before {
	content: "\e9d3";
  }
  .icon-cloud-moon-meatball:before {
	content: "\e9d4";
  }
  .icon-cloud-moon-rain:before {
	content: "\e9d5";
  }
  .icon-cloud-moon-showers:before {
	content: "\e9d6";
  }
  .icon-cloud-moon:before {
	content: "\e9d7";
  }
  .icon-cloud-question:before {
	content: "\e9d8";
  }
  .icon-cloud-rain-sun:before {
	content: "\e9d9";
  }
  .icon-cloud-rain:before {
	content: "\e9da";
  }
  .icon-cloud-redo:before {
	content: "\e9db";
  }
  .icon-cloud-set:before {
	content: "\e9dc";
  }
  .icon-cloud-share:before {
	content: "\e9dd";
  }
  .icon-cloud-shield:before {
	content: "\e9de";
  }
  .icon-cloud-showers-alt:before {
	content: "\e9df";
  }
  .icon-cloud-showers-heavy:before {
	content: "\e9e0";
  }
  .icon-cloud-showers:before {
	content: "\e9e1";
  }
  .icon-cloud-slash:before {
	content: "\e9e2";
  }
  .icon-cloud-sun-hail:before {
	content: "\e9e3";
  }
  .icon-cloud-sun-meatball:before {
	content: "\e9e4";
  }
  .icon-cloud-sun-rain-alt:before {
	content: "\e9e5";
  }
  .icon-cloud-sun-rain:before {
	content: "\e9e6";
  }
  .icon-cloud-sun-tear:before {
	content: "\e9e7";
  }
  .icon-cloud-sun:before {
	content: "\e9e8";
  }
  .icon-cloud-times:before {
	content: "\e9e9";
  }
  .icon-cloud-unlock:before {
	content: "\e9ea";
  }
  .icon-cloud-upload:before {
	content: "\e9eb";
  }
  .icon-cloud-wifi:before {
	content: "\e9ec";
  }
  .icon-cloud-wind:before {
	content: "\e9ed";
  }
  .icon-cloud:before {
	content: "\e9ee";
  }
  .icon-clouds:before {
	content: "\e9ef";
  }
  .icon-club:before {
	content: "\e9f0";
  }
  .icon-code:before {
	content: "\e9f1";
  }
  .icon-coffee:before {
	content: "\e9f2";
  }
  .icon-cog:before {
	content: "\e9f3";
  }
  .icon-coins:before {
	content: "\e9f4";
  }
  .icon-columns:before {
	content: "\e9f5";
  }
  .icon-comment-alt-block:before {
	content: "\e9f6";
  }
  .icon-comment-alt-chart-lines:before {
	content: "\e9f7";
  }
  .icon-comment-alt-check:before {
	content: "\e9f8";
  }
  .icon-comment-alt-dots:before {
	content: "\e9f9";
  }
  .icon-comment-alt-download:before {
	content: "\e9fa";
  }
  .icon-comment-alt-edit:before {
	content: "\e9fb";
  }
  .icon-comment-alt-exclamation:before {
	content: "\e9fc";
  }
  .icon-comment-alt-heart:before {
	content: "\e9fd";
  }
  .icon-comment-alt-image:before {
	content: "\e9fe";
  }
  .icon-comment-alt-info:before {
	content: "\e9ff";
  }
  .icon-comment-alt-lines:before {
	content: "\ea00";
  }
  .icon-comment-alt-lock:before {
	content: "\ea01";
  }
  .icon-comment-alt-medical:before {
	content: "\ea02";
  }
  .icon-comment-alt-message:before {
	content: "\ea03";
  }
  .icon-comment-alt-notes:before {
	content: "\ea04";
  }
  .icon-comment-alt-plus:before {
	content: "\ea05";
  }
  .icon-comment-alt-question:before {
	content: "\ea06";
  }
  .icon-comment-alt-redo:before {
	content: "\ea07";
  }
  .icon-comment-alt-search:before {
	content: "\ea08";
  }
  .icon-comment-alt-share:before {
	content: "\ea09";
  }
  .icon-comment-alt-shield:before {
	content: "\ea0a";
  }
  .icon-comment-alt-slash:before {
	content: "\ea0b";
  }
  .icon-comment-alt-upload:before {
	content: "\ea0c";
  }
  .icon-comment-alt-verify:before {
	content: "\ea0d";
  }
  .icon-comment-alt:before {
	content: "\ea0e";
  }
  .icon-comment-block:before {
	content: "\ea0f";
  }
  .icon-comment-chart-line:before {
	content: "\ea10";
  }
  .icon-comment-check:before {
	content: "\ea11";
  }
  .icon-comment-dots:before {
	content: "\ea12";
  }
  .icon-comment-download:before {
	content: "\ea13";
  }
  .icon-comment-edit:before {
	content: "\ea14";
  }
  .icon-comment-exclamation:before {
	content: "\ea15";
  }
  .icon-comment-heart:before {
	content: "\ea16";
  }
  .icon-comment-image:before {
	content: "\ea17";
  }
  .icon-comment-info-alt:before {
	content: "\ea18";
  }
  .icon-comment-info:before {
	content: "\ea19";
  }
  .icon-comment-lines:before {
	content: "\ea1a";
  }
  .icon-comment-lock:before {
	content: "\ea1b";
  }
  .icon-comment-medical:before {
	content: "\ea1c";
  }
  .icon-comment-message:before {
	content: "\ea1d";
  }
  .icon-comment-notes:before {
	content: "\ea1e";
  }
  .icon-comment-plus:before {
	content: "\ea1f";
  }
  .icon-comment-question:before {
	content: "\ea20";
  }
  .icon-comment-redo:before {
	content: "\ea21";
  }
  .icon-comment-search:before {
	content: "\ea22";
  }
  .icon-comment-share:before {
	content: "\ea23";
  }
  .icon-comment-shield:before {
	content: "\ea24";
  }
  .icon-comment-slash:before {
	content: "\ea25";
  }
  .icon-comment-upload:before {
	content: "\ea26";
  }
  .icon-comment-verify:before {
	content: "\ea27";
  }
  .icon-comment:before {
	content: "\ea28";
  }
  .icon-comments-alt:before {
	content: "\ea29";
  }
  .icon-comments:before {
	content: "\ea2a";
  }
  .icon-commnet-alt-slash:before {
	content: "\ea2b";
  }
  .icon-compact-disc:before {
	content: "\ea2c";
  }
  .icon-compass:before {
	content: "\ea2d";
  }
  .icon-compress-alt-left:before {
	content: "\ea2e";
  }
  .icon-compress-alt:before {
	content: "\ea2f";
  }
  .icon-compress-arrows:before {
	content: "\ea30";
  }
  .icon-compress-lines:before {
	content: "\ea31";
  }
  .icon-compress-point:before {
	content: "\ea32";
  }
  .icon-compress-v:before {
	content: "\ea33";
  }
  .icon-compress:before {
	content: "\ea34";
  }
  .icon-computer-mouse:before {
	content: "\ea35";
  }
  .icon-confused:before {
	content: "\ea36";
  }
  .icon-constructor:before {
	content: "\ea37";
  }
  .icon-copy-alt:before {
	content: "\ea38";
  }
  .icon-copy-landscape:before {
	content: "\ea39";
  }
  .icon-copy:before {
	content: "\ea3a";
  }
  .icon-copyright:before {
	content: "\ea3b";
  }
  .icon-corner-down-left:before {
	content: "\ea3c";
  }
  .icon-corner-down-right-alt:before {
	content: "\ea3d";
  }
  .icon-corner-down-right:before {
	content: "\ea3e";
  }
  .icon-corner-left-down:before {
	content: "\ea3f";
  }
  .icon-corner-right-down:before {
	content: "\ea40";
  }
  .icon-corner-up-left-alt:before {
	content: "\ea41";
  }
  .icon-corner-up-left:before {
	content: "\ea42";
  }
  .icon-corner-up-right-alt:before {
	content: "\ea43";
  }
  .icon-corner-up-right:before {
	content: "\ea44";
  }
  .icon-creative-commons-pd-alt:before {
	content: "\ea45";
  }
  .icon-creative-commons-pd:before {
	content: "\ea46";
  }
  .icon-crockery:before {
	content: "\ea47";
  }
  .icon-crop-alt-rotate-left:before {
	content: "\ea48";
  }
  .icon-crop-alt-rotate-right:before {
	content: "\ea49";
  }
  .icon-crop-alt:before {
	content: "\ea4a";
  }
  .icon-crosshair-alt:before {
	content: "\ea4b";
  }
  .icon-crosshair:before {
	content: "\ea4c";
  }
  .icon-crosshairs:before {
	content: "\ea4d";
  }
  .icon-cube:before {
	content: "\ea4e";
  }
  .icon-data-sharing:before {
	content: "\ea4f";
  }
  .icon-database-alt:before {
	content: "\ea50";
  }
  .icon-database:before {
	content: "\ea51";
  }
  .icon-desert:before {
	content: "\ea52";
  }
  .icon-desktop-alt-slash:before {
	content: "\ea53";
  }
  .icon-desktop-alt:before {
	content: "\ea54";
  }
  .icon-desktop-cloud-alt:before {
	content: "\ea55";
  }
  .icon-desktop-slash:before {
	content: "\ea56";
  }
  .icon-desktop:before {
	content: "\ea57";
  }
  .icon-dialpad-alt:before {
	content: "\ea58";
  }
  .icon-dialpad:before {
	content: "\ea59";
  }
  .icon-diamond:before {
	content: "\ea5a";
  }
  .icon-diary-alt:before {
	content: "\ea5b";
  }
  .icon-diary:before {
	content: "\ea5c";
  }
  .icon-dice-five:before {
	content: "\ea5d";
  }
  .icon-dice-four:before {
	content: "\ea5e";
  }
  .icon-dice-one:before {
	content: "\ea5f";
  }
  .icon-dice-six:before {
	content: "\ea60";
  }
  .icon-dice-three:before {
	content: "\ea61";
  }
  .icon-dice-two:before {
	content: "\ea62";
  }
  .icon-direction:before {
	content: "\ea63";
  }
  .icon-directions:before {
	content: "\ea64";
  }
  .icon-dizzy-meh:before {
	content: "\ea65";
  }
  .icon-dna:before {
	content: "\ea66";
  }
  .icon-document-layout-center:before {
	content: "\ea67";
  }
  .icon-document-layout-left:before {
	content: "\ea68";
  }
  .icon-document-layout-right:before {
	content: "\ea69";
  }
  .icon-document:before {
	content: "\ea6a";
  }
  .icon-dollar-alt:before {
	content: "\ea6b";
  }
  .icon-dollar-sign-alt:before {
	content: "\ea6c";
  }
  .icon-dollar-sign:before {
	content: "\ea6d";
  }
  .icon-down-arrow:before {
	content: "\ea6e";
  }
  .icon-download-alt:before {
	content: "\ea6f";
  }
  .icon-dribbble:before {
	content: "\ea70";
  }
  .icon-drill:before {
	content: "\ea71";
  }
  .icon-dropbox:before {
	content: "\ea72";
  }
  .icon-dumbbell:before {
	content: "\ea73";
  }
  .icon-edit-alt:before {
	content: "\ea74";
  }
  .icon-edit:before {
	content: "\ea75";
  }
  .icon-ellipsis-h:before {
	content: "\ea76";
  }
  .icon-ellipsis-v:before {
	content: "\ea77";
  }
  .icon-emoji:before {
	content: "\ea78";
  }
  .icon-enter:before {
	content: "\ea79";
  }
  .icon-entry:before {
	content: "\ea7a";
  }
  .icon-envelope-add:before {
	content: "\ea7b";
  }
  .icon-envelope-alt:before {
	content: "\ea7c";
  }
  .icon-envelope-block:before {
	content: "\ea7d";
  }
  .icon-envelope-bookmark:before {
	content: "\ea7e";
  }
  .icon-envelope-check:before {
	content: "\ea7f";
  }
  .icon-envelope-download-alt:before {
	content: "\ea80";
  }
  .icon-envelope-download:before {
	content: "\ea81";
  }
  .icon-envelope-edit:before {
	content: "\ea82";
  }
  .icon-envelope-exclamation:before {
	content: "\ea83";
  }
  .icon-envelope-heart:before {
	content: "\ea84";
  }
  .icon-envelope-info:before {
	content: "\ea85";
  }
  .icon-envelope-lock:before {
	content: "\ea86";
  }
  .icon-envelope-minus:before {
	content: "\ea87";
  }
  .icon-envelope-open:before {
	content: "\ea88";
  }
  .icon-envelope-question:before {
	content: "\ea89";
  }
  .icon-envelope-receive:before {
	content: "\ea8a";
  }
  .icon-envelope-redo:before {
	content: "\ea8b";
  }
  .icon-envelope-search:before {
	content: "\ea8c";
  }
  .icon-envelope-send:before {
	content: "\ea8d";
  }
  .icon-envelope-share:before {
	content: "\ea8e";
  }
  .icon-envelope-shield:before {
	content: "\ea8f";
  }
  .icon-envelope-star:before {
	content: "\ea90";
  }
  .icon-envelope-times:before {
	content: "\ea91";
  }
  .icon-envelope-upload-alt:before {
	content: "\ea92";
  }
  .icon-envelope-upload:before {
	content: "\ea93";
  }
  .icon-envelope:before {
	content: "\ea94";
  }
  .icon-envelopes:before {
	content: "\ea95";
  }
  .icon-equal-circle:before {
	content: "\ea96";
  }
  .icon-euro-circle:before {
	content: "\ea97";
  }
  .icon-euro:before {
	content: "\ea98";
  }
  .icon-exchange-alt:before {
	content: "\ea99";
  }
  .icon-exchange:before {
	content: "\ea9a";
  }
  .icon-exclamation-circle:before {
	content: "\ea9b";
  }
  .icon-exclamation-octagon:before {
	content: "\ea9c";
  }
  .icon-exclamation-triangle:before {
	content: "\ea9d";
  }
  .icon-exclude:before {
	content: "\ea9e";
  }
  .icon-exit:before {
	content: "\ea9f";
  }
  .icon-expand-alt:before {
	content: "\eaa0";
  }
  .icon-expand-arrows-alt:before {
	content: "\eaa1";
  }
  .icon-expand-arrows:before {
	content: "\eaa2";
  }
  .icon-expand-from-corner:before {
	content: "\eaa3";
  }
  .icon-expand-left:before {
	content: "\eaa4";
  }
  .icon-expand-right:before {
	content: "\eaa5";
  }
  .icon-export:before {
	content: "\eaa6";
  }
  .icon-exposure-alt:before {
	content: "\eaa7";
  }
  .icon-exposure-increase:before {
	content: "\eaa8";
  }
  .icon-external-link-alt:before {
	content: "\eaa9";
  }
  .icon-eye-slash:before {
	content: "\eaaa";
  }
  .icon-eye:before {
	content: "\eaab";
  }
  .icon-facebook:before {
	content: "\eaac";
  }
  .icon-fahrenheit:before {
	content: "\eaad";
  }
  .icon-fast-mail-alt:before {
	content: "\eaae";
  }
  .icon-fast-mail:before {
	content: "\eaaf";
  }
  .icon-favorite:before {
	content: "\eab0";
  }
  .icon-feedback:before {
	content: "\eab1";
  }
  .icon-file-alt:before {
	content: "\eab2";
  }
  .icon-file-blank:before {
	content: "\eab3";
  }
  .icon-file-block-alt:before {
	content: "\eab4";
  }
  .icon-file-bookmark-alt:before {
	content: "\eab5";
  }
  .icon-file-check-alt:before {
	content: "\eab6";
  }
  .icon-file-check:before {
	content: "\eab7";
  }
  .icon-file-contract-dollar:before {
	content: "\eab8";
  }
  .icon-file-copy-alt:before {
	content: "\eab9";
  }
  .icon-file-download-alt:before {
	content: "\eaba";
  }
  .icon-file-download:before {
	content: "\eabb";
  }
  .icon-file-edit-alt:before {
	content: "\eabc";
  }
  .icon-file-exclamation-alt:before {
	content: "\eabd";
  }
  .icon-file-exclamation:before {
	content: "\eabe";
  }
  .icon-file-heart:before {
	content: "\eabf";
  }
  .icon-file-info-alt:before {
	content: "\eac0";
  }
  .icon-file-landscape-alt:before {
	content: "\eac1";
  }
  .icon-file-landscape:before {
	content: "\eac2";
  }
  .icon-file-lanscape-slash:before {
	content: "\eac3";
  }
  .icon-file-lock-alt:before {
	content: "\eac4";
  }
  .icon-file-medical-alt:before {
	content: "\eac5";
  }
  .icon-file-medical:before {
	content: "\eac6";
  }
  .icon-file-minus-alt:before {
	content: "\eac7";
  }
  .icon-file-minus:before {
	content: "\eac8";
  }
  .icon-file-network:before {
	content: "\eac9";
  }
  .icon-file-plus-alt:before {
	content: "\eaca";
  }
  .icon-file-plus:before {
	content: "\eacb";
  }
  .icon-file-question-alt:before {
	content: "\eacc";
  }
  .icon-file-question:before {
	content: "\eacd";
  }
  .icon-file-redo-alt:before {
	content: "\eace";
  }
  .icon-file-search-alt:before {
	content: "\eacf";
  }
  .icon-file-share-alt:before {
	content: "\ead0";
  }
  .icon-file-shield-alt:before {
	content: "\ead1";
  }
  .icon-file-slash:before {
	content: "\ead2";
  }
  .icon-file-times-alt:before {
	content: "\ead3";
  }
  .icon-file-times:before {
	content: "\ead4";
  }
  .icon-file-upload-alt:before {
	content: "\ead5";
  }
  .icon-file-upload:before {
	content: "\ead6";
  }
  .icon-file:before {
	content: "\ead7";
  }
  .icon-files-landscapes-alt:before {
	content: "\ead8";
  }
  .icon-files-landscapes:before {
	content: "\ead9";
  }
  .icon-film:before {
	content: "\eada";
  }
  .icon-filter-slash:before {
	content: "\eadb";
  }
  .icon-filter:before {
	content: "\eadc";
  }
  .icon-flask-potion:before {
	content: "\eadd";
  }
  .icon-flask:before {
	content: "\eade";
  }
  .icon-flip-h-alt:before {
	content: "\eadf";
  }
  .icon-flip-h:before {
	content: "\eae0";
  }
  .icon-flip-v-alt:before {
	content: "\eae1";
  }
  .icon-flip-v:before {
	content: "\eae2";
  }
  .icon-flower:before {
	content: "\eae3";
  }
  .icon-focus-add:before {
	content: "\eae4";
  }
  .icon-focus-target:before {
	content: "\eae5";
  }
  .icon-focus:before {
	content: "\eae6";
  }
  .icon-folder-check:before {
	content: "\eae7";
  }
  .icon-folder-download:before {
	content: "\eae8";
  }
  .icon-folder-exclamation:before {
	content: "\eae9";
  }
  .icon-folder-heart:before {
	content: "\eaea";
  }
  .icon-folder-info:before {
	content: "\eaeb";
  }
  .icon-folder-lock:before {
	content: "\eaec";
  }
  .icon-folder-medical:before {
	content: "\eaed";
  }
  .icon-folder-minus:before {
	content: "\eaee";
  }
  .icon-folder-network:before {
	content: "\eaef";
  }
  .icon-folder-plus:before {
	content: "\eaf0";
  }
  .icon-folder-question:before {
	content: "\eaf1";
  }
  .icon-folder-slash:before {
	content: "\eaf2";
  }
  .icon-folder-times:before {
	content: "\eaf3";
  }
  .icon-folder-upload:before {
	content: "\eaf4";
  }
  .icon-folder:before {
	content: "\eaf5";
  }
  .icon-food:before {
	content: "\eaf6";
  }
  .icon-football-american:before {
	content: "\eaf7";
  }
  .icon-football-ball:before {
	content: "\eaf8";
  }
  .icon-football:before {
	content: "\eaf9";
  }
  .icon-forecastcloud-moon-tear:before {
	content: "\eafa";
  }
  .icon-forwaded-call:before {
	content: "\eafb";
  }
  .icon-forward:before {
	content: "\eafc";
  }
  .icon-frown:before {
	content: "\eafd";
  }
  .icon-game-structure:before {
	content: "\eafe";
  }
  .icon-game:before {
	content: "\eaff";
  }
  .icon-gift:before {
	content: "\eb00";
  }
  .icon-github:before {
	content: "\eb01";
  }
  .icon-glass-martini-alt-slash:before {
	content: "\eb02";
  }
  .icon-glass-martini-alt:before {
	content: "\eb03";
  }
  .icon-glass-martini:before {
	content: "\eb04";
  }
  .icon-glass-tea:before {
	content: "\eb05";
  }
  .icon-glass:before {
	content: "\eb06";
  }
  .icon-globe:before {
	content: "\eb07";
  }
  .icon-gold:before {
	content: "\eb08";
  }
  .icon-google-drive:before {
	content: "\eb09";
  }
  .icon-graduation-hat:before {
	content: "\eb0a";
  }
  .icon-graph-bar:before {
	content: "\eb0b";
  }
  .icon-grid:before {
	content: "\eb0c";
  }
  .icon-grids:before {
	content: "\eb0d";
  }
  .icon-grin-tongue-wink-alt:before {
	content: "\eb0e";
  }
  .icon-grin-tongue-wink:before {
	content: "\eb0f";
  }
  .icon-grin:before {
	content: "\eb10";
  }
  .icon-grip-horizontal-line:before {
	content: "\eb11";
  }
  .icon-hdd:before {
	content: "\eb12";
  }
  .icon-headphones-alt:before {
	content: "\eb13";
  }
  .icon-headphones:before {
	content: "\eb14";
  }
  .icon-heart-alt:before {
	content: "\eb15";
  }
  .icon-heart-medical:before {
	content: "\eb16";
  }
  .icon-heart-rate:before {
	content: "\eb17";
  }
  .icon-heart-sign:before {
	content: "\eb18";
  }
  .icon-heart:before {
	content: "\eb19";
  }
  .icon-heartbeat:before {
	content: "\eb1a";
  }
  .icon-history-alt:before {
	content: "\eb1b";
  }
  .icon-history:before {
	content: "\eb1c";
  }
  .icon-home-alt:before {
	content: "\eb1d";
  }
  .icon-home:before {
	content: "\eb1e";
  }
  .icon-horizontal-align-center:before {
	content: "\eb1f";
  }
  .icon-horizontal-align-left:before {
	content: "\eb20";
  }
  .icon-horizontal-align-right:before {
	content: "\eb21";
  }
  .icon-horizontal-distribution-center:before {
	content: "\eb22";
  }
  .icon-horizontal-distribution-left:before {
	content: "\eb23";
  }
  .icon-horizontal-distribution-right:before {
	content: "\eb24";
  }
  .icon-hunting:before {
	content: "\eb25";
  }
  .icon-image-alt-slash:before {
	content: "\eb26";
  }
  .icon-image-block:before {
	content: "\eb27";
  }
  .icon-image-broken:before {
	content: "\eb28";
  }
  .icon-image-check:before {
	content: "\eb29";
  }
  .icon-image-download:before {
	content: "\eb2a";
  }
  .icon-image-edit:before {
	content: "\eb2b";
  }
  .icon-image-lock:before {
	content: "\eb2c";
  }
  .icon-image-minus:before {
	content: "\eb2d";
  }
  .icon-image-plus:before {
	content: "\eb2e";
  }
  .icon-image-question:before {
	content: "\eb2f";
  }
  .icon-image-redo:before {
	content: "\eb30";
  }
  .icon-image-resize-landscape:before {
	content: "\eb31";
  }
  .icon-image-resize-square:before {
	content: "\eb32";
  }
  .icon-image-search:before {
	content: "\eb33";
  }
  .icon-image-share:before {
	content: "\eb34";
  }
  .icon-image-shield:before {
	content: "\eb35";
  }
  .icon-image-slash:before {
	content: "\eb36";
  }
  .icon-image-times:before {
	content: "\eb37";
  }
  .icon-image-upload:before {
	content: "\eb38";
  }
  .icon-image-v:before {
	content: "\eb39";
  }
  .icon-image:before {
	content: "\eb3a";
  }
  .icon-images:before {
	content: "\eb3b";
  }
  .icon-incoming-call:before {
	content: "\eb3c";
  }
  .icon-info-circle:before {
	content: "\eb3d";
  }
  .icon-instagram-1:before {
	content: "\eb3e";
  }
  .icon-instagram:before {
	content: "\eb3f";
  }
  .icon-invoice:before {
	content: "\eb40";
  }
  .icon-jackhammer:before {
	content: "\eb41";
  }
  .icon-kayak:before {
	content: "\eb42";
  }
  .icon-key-skeleton-alt:before {
	content: "\eb43";
  }
  .icon-key-skeleton:before {
	content: "\eb44";
  }
  .icon-keyboard-alt:before {
	content: "\eb45";
  }
  .icon-keyboard-hide:before {
	content: "\eb46";
  }
  .icon-keyboard-show:before {
	content: "\eb47";
  }
  .icon-keyboard:before {
	content: "\eb48";
  }
  .icon-keyhole-circle:before {
	content: "\eb49";
  }
  .icon-keyhole-square-full:before {
	content: "\eb4a";
  }
  .icon-keyhole-square:before {
	content: "\eb4b";
  }
  .icon-kid:before {
	content: "\eb4c";
  }
  .icon-label-alt:before {
	content: "\eb4d";
  }
  .icon-label:before {
	content: "\eb4e";
  }
  .icon-lamp:before {
	content: "\eb4f";
  }
  .icon-laptop-cloud:before {
	content: "\eb50";
  }
  .icon-laptop:before {
	content: "\eb51";
  }
  .icon-laughing:before {
	content: "\eb52";
  }
  .icon-layer-group-slash:before {
	content: "\eb53";
  }
  .icon-layer-group:before {
	content: "\eb54";
  }
  .icon-layers-alt:before {
	content: "\eb55";
  }
  .icon-layers-slash:before {
	content: "\eb56";
  }
  .icon-layers:before {
	content: "\eb57";
  }
  .icon-left-arrow-from-left:before {
	content: "\eb58";
  }
  .icon-left-arrow-to-left:before {
	content: "\eb59";
  }
  .icon-left-indent-alt:before {
	content: "\eb5a";
  }
  .icon-left-indent:before {
	content: "\eb5b";
  }
  .icon-life-ring:before {
	content: "\eb5c";
  }
  .icon-lightbulb-alt:before {
	content: "\eb5d";
  }
  .icon-lightbulb:before {
	content: "\eb5e";
  }
  .icon-line-alt:before {
	content: "\eb5f";
  }
  .icon-line-spacing:before {
	content: "\eb60";
  }
  .icon-link-alt:before {
	content: "\eb61";
  }
  .icon-link-broken:before {
	content: "\eb62";
  }
  .icon-link-h:before {
	content: "\eb63";
  }
  .icon-link:before {
	content: "\eb64";
  }
  .icon-linkedin:before {
	content: "\eb65";
  }
  .icon-list-ui-alt:before {
	content: "\eb66";
  }
  .icon-list-ul:before {
	content: "\eb67";
  }
  .icon-location-arrow-alt:before {
	content: "\eb68";
  }
  .icon-location-arrow:before {
	content: "\eb69";
  }
  .icon-location-pin-alt:before {
	content: "\eb6a";
  }
  .icon-location-point:before {
	content: "\eb6b";
  }
  .icon-location:before {
	content: "\eb6c";
  }
  .icon-lock-access:before {
	content: "\eb6d";
  }
  .icon-lock-alt:before {
	content: "\eb6e";
  }
  .icon-lock-open-alt:before {
	content: "\eb6f";
  }
  .icon-lock-slash:before {
	content: "\eb70";
  }
  .icon-lock:before {
	content: "\eb71";
  }
  .icon-mailbox-alt:before {
	content: "\eb72";
  }
  .icon-mailbox:before {
	content: "\eb73";
  }
  .icon-map-marker-alt:before {
	content: "\eb74";
  }
  .icon-map-marker-edit:before {
	content: "\eb75";
  }
  .icon-map-marker-info:before {
	content: "\eb76";
  }
  .icon-map-marker-minus:before {
	content: "\eb77";
  }
  .icon-map-marker-plus:before {
	content: "\eb78";
  }
  .icon-map-marker-question:before {
	content: "\eb79";
  }
  .icon-map-marker-shield:before {
	content: "\eb7a";
  }
  .icon-map-marker-slash:before {
	content: "\eb7b";
  }
  .icon-map-marker:before {
	content: "\eb7c";
  }
  .icon-map-pin-alt:before {
	content: "\eb7d";
  }
  .icon-map-pin:before {
	content: "\eb7e";
  }
  .icon-map:before {
	content: "\eb7f";
  }
  .icon-mars:before {
	content: "\eb80";
  }
  .icon-maximize-left:before {
	content: "\eb81";
  }
  .icon-medal:before {
	content: "\eb82";
  }
  .icon-medical-drip:before {
	content: "\eb83";
  }
  .icon-medical-square-full:before {
	content: "\eb84";
  }
  .icon-medical-square:before {
	content: "\eb85";
  }
  .icon-medical:before {
	content: "\eb86";
  }
  .icon-medkit:before {
	content: "\eb87";
  }
  .icon-meeting-board:before {
	content: "\eb88";
  }
  .icon-meh-alt:before {
	content: "\eb89";
  }
  .icon-meh-closed-eye:before {
	content: "\eb8a";
  }
  .icon-meh:before {
	content: "\eb8b";
  }
  .icon-message:before {
	content: "\eb8c";
  }
  .icon-microphone-slash:before {
	content: "\eb8d";
  }
  .icon-microphone:before {
	content: "\eb8e";
  }
  .icon-minus-circle:before {
	content: "\eb8f";
  }
  .icon-minus-path:before {
	content: "\eb90";
  }
  .icon-minus-square-full:before {
	content: "\eb91";
  }
  .icon-minus-square:before {
	content: "\eb92";
  }
  .icon-minus:before {
	content: "\eb93";
  }
  .icon-missed-call:before {
	content: "\eb94";
  }
  .icon-mobey-bill-slash:before {
	content: "\eb95";
  }
  .icon-mobile-android-alt:before {
	content: "\eb96";
  }
  .icon-mobile-android:before {
	content: "\eb97";
  }
  .icon-mobile-vibrate:before {
	content: "\eb98";
  }
  .icon-modem:before {
	content: "\eb99";
  }
  .icon-money-bill-stack:before {
	content: "\eb9a";
  }
  .icon-money-bill:before {
	content: "\eb9b";
  }
  .icon-money-insert:before {
	content: "\eb9c";
  }
  .icon-money-stack:before {
	content: "\eb9d";
  }
  .icon-money-withdraw:before {
	content: "\eb9e";
  }
  .icon-money-withdrawal:before {
	content: "\eb9f";
  }
  .icon-moneybag-alt:before {
	content: "\eba0";
  }
  .icon-moneybag:before {
	content: "\eba1";
  }
  .icon-monitor-heart-rate:before {
	content: "\eba2";
  }
  .icon-monitor:before {
	content: "\eba3";
  }
  .icon-moon-eclipse:before {
	content: "\eba4";
  }
  .icon-moon:before {
	content: "\eba5";
  }
  .icon-moonset:before {
	content: "\eba6";
  }
  .icon-mountains-sun:before {
	content: "\eba7";
  }
  .icon-mountains:before {
	content: "\eba8";
  }
  .icon-mouse-alt:before {
	content: "\eba9";
  }
  .icon-mouse:before {
	content: "\ebaa";
  }
  .icon-multiply:before {
	content: "\ebab";
  }
  .icon-music-note:before {
	content: "\ebac";
  }
  .icon-music-tune-slash:before {
	content: "\ebad";
  }
  .icon-music:before {
	content: "\ebae";
  }
  .icon-n-a:before {
	content: "\ebaf";
  }
  .icon-navigator:before {
	content: "\ebb0";
  }
  .icon-nerd:before {
	content: "\ebb1";
  }
  .icon-newspaper:before {
	content: "\ebb2";
  }
  .icon-ninja:before {
	content: "\ebb3";
  }
  .icon-no-entry:before {
	content: "\ebb4";
  }
  .icon-notebooks:before {
	content: "\ebb5";
  }
  .icon-notes:before {
	content: "\ebb6";
  }
  .icon-object-group:before {
	content: "\ebb7";
  }
  .icon-object-ungroup:before {
	content: "\ebb8";
  }
  .icon-octagon:before {
	content: "\ebb9";
  }
  .icon-outgoing-call:before {
	content: "\ebba";
  }
  .icon-package:before {
	content: "\ebbb";
  }
  .icon-padlock:before {
	content: "\ebbc";
  }
  .icon-paint-tool:before {
	content: "\ebbd";
  }
  .icon-panorama-h-alt:before {
	content: "\ebbe";
  }
  .icon-panorama-h:before {
	content: "\ebbf";
  }
  .icon-panorama-v:before {
	content: "\ebc0";
  }
  .icon-paperclip:before {
	content: "\ebc1";
  }
  .icon-paragraph:before {
	content: "\ebc2";
  }
  .icon-parcel:before {
	content: "\ebc3";
  }
  .icon-parking-square:before {
	content: "\ebc4";
  }
  .icon-pathfinder-unite:before {
	content: "\ebc5";
  }
  .icon-pathfinder:before {
	content: "\ebc6";
  }
  .icon-pause-circle:before {
	content: "\ebc7";
  }
  .icon-pause:before {
	content: "\ebc8";
  }
  .icon-pen:before {
	content: "\ebc9";
  }
  .icon-pentagon:before {
	content: "\ebca";
  }
  .icon-phone-alt:before {
	content: "\ebcb";
  }
  .icon-phone-pause:before {
	content: "\ebcc";
  }
  .icon-phone-slash:before {
	content: "\ebcd";
  }
  .icon-phone-times:before {
	content: "\ebce";
  }
  .icon-phone-volume:before {
	content: "\ebcf";
  }
  .icon-phone:before {
	content: "\ebd0";
  }
  .icon-picture:before {
	content: "\ebd1";
  }
  .icon-plane-arrival:before {
	content: "\ebd2";
  }
  .icon-plane-departure:before {
	content: "\ebd3";
  }
  .icon-plane-fly:before {
	content: "\ebd4";
  }
  .icon-plane:before {
	content: "\ebd5";
  }
  .icon-play-circle:before {
	content: "\ebd6";
  }
  .icon-play:before {
	content: "\ebd7";
  }
  .icon-plug:before {
	content: "\ebd8";
  }
  .icon-plus-circle:before {
	content: "\ebd9";
  }
  .icon-plus-square:before {
	content: "\ebda";
  }
  .icon-plus:before {
	content: "\ebdb";
  }
  .icon-podium:before {
	content: "\ebdc";
  }
  .icon-polygon:before {
	content: "\ebdd";
  }
  .icon-post-stamp:before {
	content: "\ebde";
  }
  .icon-postcard:before {
	content: "\ebdf";
  }
  .icon-pound-circle:before {
	content: "\ebe0";
  }
  .icon-pound:before {
	content: "\ebe1";
  }
  .icon-power:before {
	content: "\ebe2";
  }
  .icon-prescription-bottle:before {
	content: "\ebe3";
  }
  .icon-presentation-check:before {
	content: "\ebe4";
  }
  .icon-presentation-edit:before {
	content: "\ebe5";
  }
  .icon-presentation-line:before {
	content: "\ebe6";
  }
  .icon-presentation-lines-alt:before {
	content: "\ebe7";
  }
  .icon-presentation-minus:before {
	content: "\ebe8";
  }
  .icon-presentation-play:before {
	content: "\ebe9";
  }
  .icon-presentation-plus:before {
	content: "\ebea";
  }
  .icon-presentation-times:before {
	content: "\ebeb";
  }
  .icon-presentation:before {
	content: "\ebec";
  }
  .icon-previous:before {
	content: "\ebed";
  }
  .icon-pricetag-alt:before {
	content: "\ebee";
  }
  .icon-print-slash:before {
	content: "\ebef";
  }
  .icon-print:before {
	content: "\ebf0";
  }
  .icon-processor:before {
	content: "\ebf1";
  }
  .icon-pump:before {
	content: "\ebf2";
  }
  .icon-puzzle-piece:before {
	content: "\ebf3";
  }
  .icon-question-circle:before {
	content: "\ebf4";
  }
  .icon-rainbow:before {
	content: "\ebf5";
  }
  .icon-raindrops-alt:before {
	content: "\ebf6";
  }
  .icon-raindrops:before {
	content: "\ebf7";
  }
  .icon-receipt-alt:before {
	content: "\ebf8";
  }
  .icon-receipt:before {
	content: "\ebf9";
  }
  .icon-record-audio:before {
	content: "\ebfa";
  }
  .icon-redo:before {
	content: "\ebfb";
  }
  .icon-refresh:before {
	content: "\ebfc";
  }
  .icon-registered:before {
	content: "\ebfd";
  }
  .icon-repeat:before {
	content: "\ebfe";
  }
  .icon-resizer:before {
	content: "\ebff";
  }
  .icon-restaurant:before {
	content: "\ec00";
  }
  .icon-right-indent-alt:before {
	content: "\ec01";
  }
  .icon-rope-way:before {
	content: "\ec02";
  }
  .icon-rotate-360:before {
	content: "\ec03";
  }
  .icon-rss-alt:before {
	content: "\ec04";
  }
  .icon-rss-interface:before {
	content: "\ec05";
  }
  .icon-rss:before {
	content: "\ec06";
  }
  .icon-ruler-combined:before {
	content: "\ec07";
  }
  .icon-ruler:before {
	content: "\ec08";
  }
  .icon-sad-cry:before {
	content: "\ec09";
  }
  .icon-sad-crying:before {
	content: "\ec0a";
  }
  .icon-sad-dizzy:before {
	content: "\ec0b";
  }
  .icon-sad-squint:before {
	content: "\ec0c";
  }
  .icon-sad:before {
	content: "\ec0d";
  }
  .icon-scaling-left:before {
	content: "\ec0e";
  }
  .icon-scaling-right:before {
	content: "\ec0f";
  }
  .icon-scenery:before {
	content: "\ec10";
  }
  .icon-schedule:before {
	content: "\ec11";
  }
  .icon-science:before {
	content: "\ec12";
  }
  .icon-screw:before {
	content: "\ec13";
  }
  .icon-scroll-h:before {
	content: "\ec14";
  }
  .icon-scroll:before {
	content: "\ec15";
  }
  .icon-search-alt:before {
	content: "\ec16";
  }
  .icon-search-minus:before {
	content: "\ec17";
  }
  .icon-search-plus:before {
	content: "\ec18";
  }
  .icon-search:before {
	content: "\ec19";
  }
  .icon-server-alt:before {
	content: "\ec1a";
  }
  .icon-server-connection:before {
	content: "\ec1b";
  }
  .icon-server-network-alt:before {
	content: "\ec1c";
  }
  .icon-server-network:before {
	content: "\ec1d";
  }
  .icon-server:before {
	content: "\ec1e";
  }
  .icon-servers:before {
	content: "\ec1f";
  }
  .icon-servicemark:before {
	content: "\ec20";
  }
  .icon-share-alt:before {
	content: "\ec21";
  }
  .icon-shield-check:before {
	content: "\ec22";
  }
  .icon-shield-exclamation:before {
	content: "\ec23";
  }
  .icon-shield-question:before {
	content: "\ec24";
  }
  .icon-shield-slash:before {
	content: "\ec25";
  }
  .icon-shield:before {
	content: "\ec26";
  }
  .icon-ship:before {
	content: "\ec27";
  }
  .icon-shop:before {
	content: "\ec28";
  }
  .icon-shopping-basket:before {
	content: "\ec29";
  }
  .icon-shopping-cart-alt:before {
	content: "\ec2a";
  }
  .icon-shopping-trolley:before {
	content: "\ec2b";
  }
  .icon-shovel:before {
	content: "\ec2c";
  }
  .icon-shrink:before {
	content: "\ec2d";
  }
  .icon-shuffle:before {
	content: "\ec2e";
  }
  .icon-shutter-alt:before {
	content: "\ec2f";
  }
  .icon-shutter:before {
	content: "\ec30";
  }
  .icon-sick:before {
	content: "\ec31";
  }
  .icon-sign-alt:before {
	content: "\ec32";
  }
  .icon-sign-in-alt:before {
	content: "\ec33";
  }
  .icon-sign-left:before {
	content: "\ec34";
  }
  .icon-sign-out-alt:before {
	content: "\ec35";
  }
  .icon-sign-right:before {
	content: "\ec36";
  }
  .icon-signal-alt-3:before {
	content: "\ec37";
  }
  .icon-signal-alt:before {
	content: "\ec38";
  }
  .icon-signal:before {
	content: "\ec39";
  }
  .icon-silence:before {
	content: "\ec3a";
  }
  .icon-silent-squint:before {
	content: "\ec3b";
  }
  .icon-sim-card:before {
	content: "\ec3c";
  }
  .icon-sitemap:before {
	content: "\ec3d";
  }
  .icon-skip-forward-alt:before {
	content: "\ec3e";
  }
  .icon-skip-forward-circle:before {
	content: "\ec3f";
  }
  .icon-skip-forward:before {
	content: "\ec40";
  }
  .icon-sliders-v-alt:before {
	content: "\ec41";
  }
  .icon-sliders-v:before {
	content: "\ec42";
  }
  .icon-smile-beam:before {
	content: "\ec43";
  }
  .icon-smile-dizzy:before {
	content: "\ec44";
  }
  .icon-smile-squint-wink-alt:before {
	content: "\ec45";
  }
  .icon-smile-squint-wink:before {
	content: "\ec46";
  }
  .icon-smile-wink-alt:before {
	content: "\ec47";
  }
  .icon-smile-wink:before {
	content: "\ec48";
  }
  .icon-smile:before {
	content: "\ec49";
  }
  .icon-snow-flake:before {
	content: "\ec4a";
  }
  .icon-snowflake-alt:before {
	content: "\ec4b";
  }
  .icon-snowflake:before {
	content: "\ec4c";
  }
  .icon-sort-amount-down:before {
	content: "\ec4d";
  }
  .icon-sort-amount-up:before {
	content: "\ec4e";
  }
  .icon-sort:before {
	content: "\ec4f";
  }
  .icon-sorting:before {
	content: "\ec50";
  }
  .icon-space-key:before {
	content: "\ec51";
  }
  .icon-spade:before {
	content: "\ec52";
  }
  .icon-sperms:before {
	content: "\ec53";
  }
  .icon-spin:before {
	content: "\ec54";
  }
  .icon-sport:before {
	content: "\ec55";
  }
  .icon-square-full:before {
	content: "\ec56";
  }
  .icon-square-shape:before {
	content: "\ec57";
  }
  .icon-square:before {
	content: "\ec58";
  }
  .icon-squint:before {
	content: "\ec59";
  }
  .icon-star-half-alt:before {
	content: "\ec5a";
  }
  .icon-star:before {
	content: "\ec5b";
  }
  .icon-step-backward-alt:before {
	content: "\ec5c";
  }
  .icon-step-backward-circle:before {
	content: "\ec5d";
  }
  .icon-step-backward:before {
	content: "\ec5e";
  }
  .icon-step-forward:before {
	content: "\ec5f";
  }
  .icon-stop-circle:before {
	content: "\ec60";
  }
  .icon-stopwatch-slash:before {
	content: "\ec61";
  }
  .icon-stopwatch:before {
	content: "\ec62";
  }
  .icon-store-alt:before {
	content: "\ec63";
  }
  .icon-store:before {
	content: "\ec64";
  }
  .icon-streering:before {
	content: "\ec65";
  }
  .icon-stretcher:before {
	content: "\ec66";
  }
  .icon-subject:before {
	content: "\ec67";
  }
  .icon-subway-alt:before {
	content: "\ec68";
  }
  .icon-subway:before {
	content: "\ec69";
  }
  .icon-suitcase-alt:before {
	content: "\ec6a";
  }
  .icon-suitcase:before {
	content: "\ec6b";
  }
  .icon-sun:before {
	content: "\ec6c";
  }
  .icon-sunset:before {
	content: "\ec6d";
  }
  .icon-surprise:before {
	content: "\ec6e";
  }
  .icon-swatchbook:before {
	content: "\ec6f";
  }
  .icon-swimmer:before {
	content: "\ec70";
  }
  .icon-symbol:before {
	content: "\ec71";
  }
  .icon-sync-exclamation:before {
	content: "\ec72";
  }
  .icon-sync-slash:before {
	content: "\ec73";
  }
  .icon-sync:before {
	content: "\ec74";
  }
  .icon-syringe:before {
	content: "\ec75";
  }
  .icon-table:before {
	content: "\ec76";
  }
  .icon-tablet:before {
	content: "\ec77";
  }
  .icon-tablets:before {
	content: "\ec78";
  }
  .icon-tachometer-fast:before {
	content: "\ec79";
  }
  .icon-tag-alt:before {
	content: "\ec7a";
  }
  .icon-tag:before {
	content: "\ec7b";
  }
  .icon-tape:before {
	content: "\ec7c";
  }
  .icon-taxi:before {
	content: "\ec7d";
  }
  .icon-tear:before {
	content: "\ec7e";
  }
  .icon-technology:before {
	content: "\ec7f";
  }
  .icon-telescope:before {
	content: "\ec80";
  }
  .icon-temperature-empty:before {
	content: "\ec81";
  }
  .icon-temperature-half:before {
	content: "\ec82";
  }
  .icon-temperature-minus:before {
	content: "\ec83";
  }
  .icon-temperature-plus:before {
	content: "\ec84";
  }
  .icon-temperature-quarter:before {
	content: "\ec85";
  }
  .icon-temperature-three-quarter:before {
	content: "\ec86";
  }
  .icon-temperature:before {
	content: "\ec87";
  }
  .icon-text:before {
	content: "\ec88";
  }
  .icon-th-large:before {
	content: "\ec89";
  }
  .icon-th-slash:before {
	content: "\ec8a";
  }
  .icon-th:before {
	content: "\ec8b";
  }
  .icon-thermometer:before {
	content: "\ec8c";
  }
  .icon-thumbs-down:before {
	content: "\ec8d";
  }
  .icon-thumbs-up:before {
	content: "\ec8e";
  }
  .icon-thunderstorm-moon:before {
	content: "\ec8f";
  }
  .icon-thunderstorm-sun:before {
	content: "\ec90";
  }
  .icon-thunderstorm:before {
	content: "\ec91";
  }
  .icon-ticket:before {
	content: "\ec92";
  }
  .icon-times-circle:before {
	content: "\ec93";
  }
  .icon-times-square:before {
	content: "\ec94";
  }
  .icon-times:before {
	content: "\ec95";
  }
  .icon-toggle-off:before {
	content: "\ec96";
  }
  .icon-toggle-on:before {
	content: "\ec97";
  }
  .icon-top-arrow-from-top:before {
	content: "\ec98";
  }
  .icon-top-arrow-to-top:before {
	content: "\ec99";
  }
  .icon-tornado:before {
	content: "\ec9a";
  }
  .icon-trademark-circle:before {
	content: "\ec9b";
  }
  .icon-trademark:before {
	content: "\ec9c";
  }
  .icon-traffic-barrier:before {
	content: "\ec9d";
  }
  .icon-trash-alt:before {
	content: "\ec9e";
  }
  .icon-trash:before {
	content: "\ec9f";
  }
  .icon-trees:before {
	content: "\eca0";
  }
  .icon-triangle:before {
	content: "\eca1";
  }
  .icon-trophy:before {
	content: "\eca2";
  }
  .icon-trowel:before {
	content: "\eca3";
  }
  .icon-truck-case:before {
	content: "\eca4";
  }
  .icon-truck-loading:before {
	content: "\eca5";
  }
  .icon-truck:before {
	content: "\eca6";
  }
  .icon-tv-retro-slash:before {
	content: "\eca7";
  }
  .icon-tv-retro:before {
	content: "\eca8";
  }
  .icon-twitter:before {
	content: "\eca9";
  }
  .icon-umbrella:before {
	content: "\ecaa";
  }
  .icon-unamused:before {
	content: "\ecab";
  }
  .icon-unlock-alt:before {
	content: "\ecac";
  }
  .icon-unlock:before {
	content: "\ecad";
  }
  .icon-upload-alt:before {
	content: "\ecae";
  }
  .icon-upload:before {
	content: "\ecaf";
  }
  .icon-usd-circle:before {
	content: "\ecb0";
  }
  .icon-usd-square:before {
	content: "\ecb1";
  }
  .icon-user-check:before {
	content: "\ecb2";
  }
  .icon-user-circle:before {
	content: "\ecb3";
  }
  .icon-user-exclamation:before {
	content: "\ecb4";
  }
  .icon-user-hard-hat:before {
	content: "\ecb5";
  }
  .icon-user-kid:before {
	content: "\ecb6";
  }
  .icon-user-minus:before {
	content: "\ecb7";
  }
  .icon-user-plus:before {
	content: "\ecb8";
  }
  .icon-user-square:before {
	content: "\ecb9";
  }
  .icon-user-times:before {
	content: "\ecba";
  }
  .icon-user:before {
	content: "\ecbb";
  }
  .icon-users-alt:before {
	content: "\ecbc";
  }
  .icon-utensils-alt:before {
	content: "\ecbd";
  }
  .icon-utensils:before {
	content: "\ecbe";
  }
  .icon-vector-square-alt:before {
	content: "\ecbf";
  }
  .icon-vector-square:before {
	content: "\ecc0";
  }
  .icon-venus:before {
	content: "\ecc1";
  }
  .icon-vertical-align-bottom:before {
	content: "\ecc2";
  }
  .icon-vertical-align-center:before {
	content: "\ecc3";
  }
  .icon-vertical-align-top:before {
	content: "\ecc4";
  }
  .icon-vertical-distribute-bottom:before {
	content: "\ecc5";
  }
  .icon-vertical-distribution-center:before {
	content: "\ecc6";
  }
  .icon-vertical-distribution-top:before {
	content: "\ecc7";
  }
  .icon-video-slash:before {
	content: "\ecc8";
  }
  .icon-video:before {
	content: "\ecc9";
  }
  .icon-voicemail-rectangle:before {
	content: "\ecca";
  }
  .icon-voicemail:before {
	content: "\eccb";
  }
  .icon-volleyball:before {
	content: "\eccc";
  }
  .icon-volume-down:before {
	content: "\eccd";
  }
  .icon-volume-mute:before {
	content: "\ecce";
  }
  .icon-volume-off:before {
	content: "\eccf";
  }
  .icon-volume-up:before {
	content: "\ecd0";
  }
  .icon-volume:before {
	content: "\ecd1";
  }
  .icon-wall:before {
	content: "\ecd2";
  }
  .icon-wallet:before {
	content: "\ecd3";
  }
  .icon-watch-alt:before {
	content: "\ecd4";
  }
  .icon-watch:before {
	content: "\ecd5";
  }
  .icon-water-drop-slash:before {
	content: "\ecd6";
  }
  .icon-water-glass:before {
	content: "\ecd7";
  }
  .icon-water:before {
	content: "\ecd8";
  }
  .icon-web-grid-alt:before {
	content: "\ecd9";
  }
  .icon-web-grid:before {
	content: "\ecda";
  }
  .icon-web-section-alt:before {
	content: "\ecdb";
  }
  .icon-web-section:before {
	content: "\ecdc";
  }
  .icon-webcam:before {
	content: "\ecdd";
  }
  .icon-weight:before {
	content: "\ecde";
  }
  .icon-wheel-barrow:before {
	content: "\ecdf";
  }
  .icon-wheelchair-alt:before {
	content: "\ece0";
  }
  .icon-wheelchair:before {
	content: "\ece1";
  }
  .icon-wifi-router:before {
	content: "\ece2";
  }
  .icon-wifi-slash:before {
	content: "\ece3";
  }
  .icon-wifi:before {
	content: "\ece4";
  }
  .icon-wind:before {
	content: "\ece5";
  }
  .icon-window-grid:before {
	content: "\ece6";
  }
  .icon-window-maximize:before {
	content: "\ece7";
  }
  .icon-window-restore:before {
	content: "\ece8";
  }
  .icon-window-section:before {
	content: "\ece9";
  }
  .icon-window:before {
	content: "\ecea";
  }
  .icon-windsock:before {
	content: "\eceb";
  }
  .icon-wrench:before {
	content: "\ecec";
  }
  .icon-yellow:before {
	content: "\eced";
  }
  .icon-yen-circle:before {
	content: "\ecee";
  }
  .icon-yen:before {
	content: "\ecef";
  }
  .icon-youtube-alt:before {
	content: "\ecf0";
  }
  .icon-youtube:before {
	content: "\ecf1";
  }
  