
@import "helpers/import";
@import "component/all";


html, body {
    height: auto !important;
    font-size: 16px;
}

body{
    background: $body-bg;
    font-family: $font-family-base;
    font-size: $font-size-base;  
    line-height:$line-height-base;
    color: $body-color;
    font-weight: $font-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
    // background: url(#{$assetPath}/confirm-cancel.jpg);
    //ToDo: below code for demo
    // background:$primary;
    // @include media(up, $breakpoint-xl) {
    //     background: $white;
    // }
}
.cursor-pointer{
  cursor: pointer;
}
.main-layout {
    min-height: calc(100vh);
    display: block;
    padding-top: 5rem;
    @include mx-991{
        padding-top: 4.25rem; 
    }
}

// Begin: Links
 a {
    text-decoration:$link-decoration;
    color: $link-color;
    cursor: pointer;
    outline: 0;
    &:hover, &:focus{
        text-decoration:$link-decoration;
        outline:0;
        color:$link-hover-color; 
    }
}
.text-link{
    color:$primary; 
    position: relative;
    &:after{
        display: block;
        content: '';
        position: absolute;
        top: 1rem;
        bottom: 0;
        left: 0;
        width: 0%;
        @include css3-prefix(transition, width 0.3s ease);
    }
    &:hover{
        color:$primary;
        &:after{ 
            width: 100%;
            border-bottom: 1px solid $primary; 
            opacity: 0.8;
        }
    }    
}
// Begin: helper Classe
p {
    margin-top:0;
    margin-bottom:$paragraph-margin-bottom;
}
img{
   max-width: 100%;
}

// Layout
body, html{
    height: 100%;
    scroll-behavior: smooth;
}
// Custom Scroll with CSS only Supported Browser Chrome, Safari and Firefox
*{
    scrollbar-color: rgba($black,0.3) transparent;
    scrollbar-width: thin;
 }
 ::-webkit-scrollbar-track {
    box-shadow: none;  
    -webkit-box-shadow: none;
    background-color: transparent;
}
::-webkit-scrollbar{
    width: 6px;
    height: 6px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb{
    background-color: rgba($black,0.3);
}





@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* nav */
  .app-header{
    box-shadow: 0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06);
    background: $white;
    z-index: 10;
    position: fixed;
    top: 0px;
    width: 100%;
   padding: 27px;
  }
  .main{
    display: flex;
    padding-top: 80px;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  /* end nav */
  
  /* modal */
  .modal-dialog{ 
    max-width: 47.5rem;
    &.modal-Small{
      max-width: 28rem;
    }
    .modalWith-close{
      .btn-close{
        opacity: 0.8;
      }
    }
    .modal-content{
      border: 1px solid #CBD5E0;
    }
    .modal-header{
      border-top-left-radius: .625rem;
      border-top-right-radius: .625rem;
    }
    .modal-footer{
      border-bottom-left-radius: .625rem;
      border-bottom-right-radius: .625rem;
    }
   .modal-content{
    border-radius: .625rem;
    }
    .modal-header{
      padding: 1.125rem;
      background: linear-gradient(0deg, #F7FAFC, #F7FAFC), $white;
      border-bottom: 1px solid #CBD5E0;
    }
    .modal-footer{
      background: linear-gradient(0deg, #F7FAFC, #F7FAFC), $white;
      border-radius: 0px 0px 10px 10px;
    }
    .modal-body{
      padding: 0px;
      .modal-body-inner{
        padding: 2rem 2rem 0px 2rem;
        &.signature-wrap{
          padding-top: 4rem;
          padding-bottom: 2rem;
        }
      }
    }
  }
  .addNote-modal{
    .modal-header{
      background: transparent;
      border: none;
      padding: 1.5rem 1.5rem .375rem 1.5rem;
    }
    .modal-title {
      font-size: 1.125rem;
    }
    .modal-body{
      padding: 1.5rem;
    }
  }
  
  /* end modal */
  
  
  // dropdown manu
  .dropdown-menu{
    border-radius: .25rem;
    padding: .5rem;
    min-width: 14rem;
    top: .75rem!important;
    .dropdown-item{
      background: #F7FAFC;
      border-radius: 2px;
      padding: .5rem .625rem;
      font-size: .875rem;
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: .25rem;
      color: #1A202C;
      transition: all 0.2s;
      &:hover{
        box-shadow: 0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06);
      }
      [class*="icon-"] {
        font-size: 1rem;
        margin-right: .3125rem;
        width: 1.125rem;
        color: inherit;
      }
    }
  }
  // end dropdown manu
  
  // common
  .btn-heading-wrap{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include mx-767{
      flex-flow: column;
      .btn{
        margin-bottom: 1.5rem;
      }
    }
  }
  .big-heading{
    margin:4rem 0px;
    @include mx-767{
      margin:3rem 0px;
    }
  }
  .input-withSelect{
    display: flex;  
    .form-group{
      &:first-child{
        flex:1 1 10.5rem;
        padding-right: var(--bs-gutter-x) ;
        @include mx-575{
          flex:1 1 50%;
        }
      }
    }
    .form-group{
      &:nth-child(2){
        flex:1 1 calc(100% - 10.5rem);
        @include mx-575{
          flex:1 1 calc(100% - 50%);
        }
      }
    }
  }
  // common
  
  // profile 
  .label-heading{
    color: #000;
    &.heading-space{
        margin-top: 1.625rem;
    }
  }
  
  .profile-wrap{
    margin-bottom: 2rem;
    .profile-block{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  [role="button"]{
      width: 10rem;
      height: 10rem;
    }
    .preview-wrap, .preview-img{
      width: 100%;
      border-radius: 100%;
      height: 100%;
    }
    .preview-img{
      background: #F7FAFC;
      display: flex;
      align-items: center;
      justify-content: center;
      .preview-action{
        position: absolute;
        color: $white;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        background: rgba(0, 0, 0, 0.2);
        .preview-ic {
          position: static;
          background: transparent;
          width: auto;
          font-size: 1.25rem;
          margin-bottom: .375rem;
          height: auto;
        }
      }
      img{
        width: 100%;
      }
      .background-img{
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-size: cover;
      }
    }
    .edit-banner-img{
    
    }
    &.client-profile-wrap{
      .preview-action{
        align-items: flex-start;
        padding: 1rem;
        top: auto;
        border-radius: 100%;
        background: $primary;
        transform: translate(0px, 65%);
        flex-flow: row;
        transition: all 0.2s;
        &:hover{
          background: rgba(24, 29, 213, 0.5);
          align-items: center;
          transform: translate(0px, 0%);
        }
      }
    }
  }
  .profile-upload{
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    border-radius: 50%;
    background: $gray-border;
    text-align: center;
    [class*="icon-"]{
      font-size: 1.3125rem;
      margin-bottom: .75rem;
      color: #718096;
    }
    .profileUpload-heading{
      margin-bottom: .25rem;
      color: #718096;
    }
    .profileUpload-subheading{
      width: 4.75rem;
      color: #718096;
    }
    .noConsultation-wrap{
        margin-top: 2.875rem;
    }
   
  }
.client-profile-sign{
    position: relative;
    border: 1px solid #e9ecef;
    background: $white;
    border-radius: .3125rem;
    margin-bottom: 2.5rem;
    .do-sign-wrap{
      position: absolute; 
      left: .625rem;       
      top: .625rem; 
      color: #718096;
      user-select: none;
  }
    .signatureReset-btn{
        position: absolute; 
        right: .625rem;       
        bottom: .625rem; 
        [class*="icon-"] {
            margin-right: .5rem;
            font-size: .875rem;
        }   
    }
   .signatureReset-btn{
     background: $white;
   }
}
.dashboard-footer{
  position: fixed;
  bottom: 0px;
  left: 220px;
  right: 0px;
  box-shadow: 0px -4px 4px -1px rgba(26, 32, 44, 0.04), 0px -2px 6px -1px rgba(26, 32, 44, 0.1);
  padding: .8125rem;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.75rem;
  z-index: 7;
  @include mx-991{
    left: 0px;
  }
}
.profile-main-wrap{
  padding-bottom: 3.75rem;
  .idea-subheading{
    max-width: 455px;
  }
  .idea-box{
    align-items: center;
    margin-bottom: 1.25rem;
  }
  .personal-info-save-btn{
    margin-top: .375rem;
    margin-bottom: 3.75rem;
  }
}
.password-reset-btn{
  margin-top: .75rem;
  margin-bottom: 2.5rem;
}
  // end profile 

  // idea box
  .idea-box {
    margin: 1rem 0px;
    display: flex;
    align-items: flex-start;
    background: #F7FAFC;
    border-radius: .3125rem;
    padding: 1.375rem 1rem;
    max-width: 40.5rem;
    margin-bottom: 2rem;
    &.box-size-478{
      max-width: 478px;
    }
    .text-size-455{
      max-width: 28.4375rem;
    }
    .idea-heading{
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: .5rem;
    }
    .idea-btn{
      text-align: right;
    }
    .idea-ic {
        flex: 1 1 4.8125rem;
        max-width: 4.8125rem;
        [class*="icon-"] {
             width: 3.75rem;
             height: 3.75rem;
             display: flex;
             align-items: center;
             justify-content: center;
             color: $light-black;
             font-size: 1.5rem;
             background: rgba(244, 248, 79, 0.3);
             border-radius: 100%;
         }
    }
    .idea-text {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        flex: 1 1 calc(100% - 4.8125rem);
        max-width: calc(100% - 4.8125rem);
    }
}
  // end idea box

  // tag block
  .tag-block {
    display: flex;
    flex-wrap: wrap;
    .tag-tile {
        padding: .1875rem .5rem;
        border-radius: 1.875rem;
        background: $light-black;
        font-weight: 700;
        font-size: .6875rem;
        line-height: 1rem;
        color: $white;
        margin-right: .5rem;
        margin-bottom: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.blue-tag-block{
      .tag-tile {
        background: $primary;
      }
    }
    &.red-tag-block{
      .tag-tile {
        background: $danger-dark;
      }
    }
    &.lightGrey-tag-block{
      .tag-tile {
        color: $dark-gray;
        background: $light;
      }
    }
    &.success-tag-block{
      .tag-tile {
        background: $success-light;
      }
    }
    &.green-tag-block{
      .tag-tile {
        background: $green;
      }
    }
    &.red-tag-block{
      .tag-tile {
        background: $red;
      }
    }
    &.normalText-tag-block{
      .tag-tile {
        font-weight: 400;
      }
    }
}
.singl-tag-tile {
  padding: .1875rem .5rem;
  border-radius: 1.875rem;
  font-weight: 700;
  font-size: .6875rem;
  line-height: 1rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:not([class*="-text"]){
    color: $white;
  }
}
  // end tag block

  // payment
  .paymentStatus-block{
    margin-bottom: 1.25rem;
    .paymentStatus-tile{
      border: 1px solid $gray-border;
      background-color: $white;
      border-radius: .625rem;
      margin-bottom: .75rem;
        .paymentStatus-innerTile{
          padding: 1rem;
        }
        .paymentStatus-heading{
          margin-bottom: 11px;
        }
        .paymentStatus-footer{
          border-top:1px solid $gray-border;
          padding: .5rem 1rem;
        
        }
      }
      
  }
  // end payment

  // task complete
 
:root {
    --leftSpace: calc(((100vw - 63.375rem) / 2) + 15px);
}
  .pendingTask-wrap{
    min-width: var(--leftSpace);
   .slick-slider{
     .slick-arrow{
       display: none!important;
     }
      .slick-track{
      display: flex;
    }
    .slick-slide{
      width: 21rem;
      padding-right: 1.5rem;
    }
  }
    .pendingTask-tile{
      width: 100%;
      border: 1px solid $gray-border;
      border-radius: .625rem;
      margin-bottom: .75rem;
      .pendingTask-inner-tile{
        padding: 16px;
      }
      .pendingTask-foter{
        padding: 1rem;
        border-top: 1px solid $gray-border;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .task-action{
          flex: 1 ;
          a:first-child{
            margin-right: 1rem;
          }
        }
      }
    }
    .clientCheck-details{
      display: flex;
      align-items: center;
      [class*="icon-"]{
        font-size: 1.375rem;
        margin-left: .375rem;
      }
    }
   
  }
  // end task complete
  // common user wrap
  .user-block{
    display: flex;
    align-items: center;
    .user-text{ 
      flex: 1 1 auto;
      max-width: 100%;
    }
    .userImg-block{
      flex: 0 0 auto;

      .user-img{
        width: 3.75rem;
        height: 3.75rem;
        flex: 1 1 3.75rem;
        border-radius:100%;
        background-size: cover;
        margin-right: 1rem;
        &.rounded-border{
          border-radius: .75rem;
        }
      }
    
      .userIc{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.75rem;
        height: 3.75rem;
        flex: 1 1 3.75rem;
        border-radius: 100%;
        margin-right: 1rem;
        &.info-light-bg{
          background-color: $info;
        }
        &.light-bg{
          background-color: $light;
        }
        &.danger-bg{
          background-color: $danger;
        }
        &.danger-dark-bg{
          background-color: $danger-dark;
        }
        &.success-bg{
          background-color: $success;
        }
        &.success-light-bg{
          background-color: $success-light;
        }
        &.warning-bg{
          background-color: $warning;
        }
        &.warning-light-bg{
          background-color: $warning-light;
        }
        &.info-dark-bg{
          background-color: $info-dark;
        }
        &.orange-bg{
          background-color: $orange;
        }
        [class*="icon-"]{
          font-size: 1.25rem;
        }
      }
    }
    &.user-block-lg{
      .user-img{
        width: 10rem;
        height: 10rem;
        margin-right: 2rem;
      }
    }
    &.user-block-md{
      .user-img  {
        flex: 0 0 5.5rem;
        width: 5.5rem;
        height: 5.5rem;
       
      }
    }
    &.user-block-sm{
      .user-img  {
        flex: 0 0 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
       
      }
      .userIc{
        flex: 0 0 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  // end common user wrap
  // appointment list
 
  .appointment-wrap{
    margin-top: 3.5rem;
  .appointment-tile{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray-border;
    box-sizing: border-box;
    box-shadow: 2px 2px 20px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 12px;
    flex-wrap: wrap;
    @include mx-991{
      justify-content: space-between;
    }
    .user-block{
      flex: 0 0 18.125rem;
      @include mx-1199{
        flex: 0 0 50%;
      }
      @include mx-991{
        flex: 0 0 100%;
      }
    }
    .appointment-date-wrap{
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-evenly;
      [class*="appointment-"]{
        padding:.5rem;
        white-space: nowrap;
      }
      @include mx-1199{
        flex: 0 0 50%;
        justify-content: flex-end;
      }
      @include mx-991{
        flex: 0 0 auto;
        justify-content: center;
      }
    }
  }
  }
  // end appointment list

  // tabs
  .nav-tabs{
    border: none;
    height: 2.25rem;
    overflow: auto;
    @include mx-575{
     
      align-items: center;
      width: 100%;
      overflow: auto;
      flex-wrap: nowrap;
      white-space: nowrap;
      height: 36px;
    }
    .nav-link{
      border-bottom: 2px solid $gray-border;
      border-width:0px 0px 2px 0px ;
      padding: .5rem;
      color: $dark;
      outline: none;
      font-size: .875rem;
     
      @include mx-575{
        padding: .3125rem;
      }
      &.active{
        color: $black;
        border-color: $primary;
        background: transparent;
      }
      &:hover{
        color: $black;
        background: transparent;
      }
    }
  }
  // end tabs

  // breadcrumb
  .breadcrumb{
    align-items: center;
    padding-top: 3.125rem;
    margin-bottom: 2.25rem;
   li{ 
     padding: .25rem 0px;
     &.active{
       padding-left: 0px;
       &::before{
         display: none;
         padding: 0px;
       }
       [class*="icon-"]{
         display: none;
       }
       &, a{
         color: $light-black;
       }
     }
     a{
       display: flex;
      color: $dark;
      [class*="icon-"]{
        margin-left: 2.25rem;
        margin-right: 2.25rem;
        color: inherit;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
      }
    }
    }
  }
  //end  breadcrumb


  // current report
  .current-report-wrap{
    .profileInfo-heading{
      margin-bottom: 1.125rem;
    }
    .user-block-lg {
      max-width: 43.125rem;
      width: 100%;
        padding-top: 2rem;
      padding-bottom: 4rem;
    
      
      @include mx-767{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
      .user-text{
        width: 31.25rem;
        .user-textHeading, .user-textSubHeading{
          line-height: 1.25rem;
        }
      }
    }
  }
  // end current report

  // profile info
.profileInfo-block{
  padding-bottom: 2rem;
    .profileInfo-tile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid $gray-border;
    box-sizing: border-box;
    border-radius: .625rem;
    padding: 1rem 1.5rem;
    margin-bottom: .75rem;
    flex-wrap: wrap;
    &:not(:last-child){
      margin-bottom: 1rem;
    }
   
    .profileInfo-action{
      .fixWidth-btn{
        width: 7.5rem;
      }
      @include mx-991{
        padding-left: 4.5rem;
        padding-top: .625rem;
        .fixWidth-btn{
          max-width: 7.5rem;
          width: 100%;
        }
      }
    }
    .profileInfo-middle-block{
      flex: 1 ;
    }
    .profileInfo-action{
      min-width: 2.5rem;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    .user-block{
      flex: 1 1 18.125rem;
      padding-right: .5rem;
      @include mx-991{
        flex: 1 1 100%;
      }
    }
    &.active{
      background: rgba(39, 174, 96, 0.05);
      border: 2px solid #27AE60;
    }
  }
}
.currentReportProfile-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  @include mx-767{
    .user-block-lg{
      .h3{
        text-align: center;
      }
      .tag-block{
        justify-content: center;
      }
  }
  }
  @include mx-991{
    flex-flow: column;
    align-items: flex-start;
  }
  .currentReportProfile-action{
    @include mx-991{
      max-width: 31.25rem;
      margin: 0 auto;
      margin-top: 1.5rem;
    }
  }
}
  // end profile info

  // dashboard 
.dashboard-wrap{
  .statusMain-block{
    max-width:100%;
    margin: 0 auto;
  }
}


  // end dashboard 
  // list
  .dashboardList-block{
    padding: 0px;
    list-style: none;
    .dashboardList-circle{
      margin-right: 4;
    }
  }
  // end list

//report status
.reportStatus-block{
  .reportStatus-tile{
    background: #F7FAFC;
    border-radius: .625rem;
    padding: 1rem 1.5rem;
    &:not(:last-child){
      margin-bottom: .5rem;
    }
  }
  .reportStatus-action{
     .reportStatus-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        background: $gray;
        width: 2rem;
        height: 2rem;
        font-size: 1.25rem;
        border-radius:100%;
        border: none;
        transition: all 0.2s;
        &:hover{
          box-shadow: 0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06);
        }
    }
  }
}   
// end report status

// store
.order-wrap{
  [class*="col-"]{
    margin-bottom: 1rem;
  }
  .order-block{
  display: flex;
  flex-flow: column;
  border-radius: .625rem;
  overflow: hidden;
  border: 1px solid $gray-border;
  background: $white;
  height: 100%;

  .order-img-block{
    max-width: 100%;
    height: 11.25rem;
    .order-img{
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
  .order-price{
    display: flex;
    align-items: center;
  }
  .order-text{
    padding: 1rem 1rem 0px 1rem;
  }
  .order-footer{
    padding: 0rem 1rem 1rem 1rem;
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    align-items: flex-end;
  }
  .new-price{
    color: #C53030;
    display: inline-block;
  
  }
  .old-price{
    position: relative;
   &::before{
     content: "";
     position: absolute;
     left: 0px;
     top: 50%;
     transform: translate(0%, -50%);
     border-bottom: 1px solid $dark;
     width: 100%;
   } 
  }
  .order-status{

    &.complete-status{
      color: #2C7A7B;
    }
    &.pending-status{
      color:$warning-dark;
    }
    &.danger-status{
      color: $danger-dark;
    }
  }
}
}
// end store

// add more
.add-more{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5625rem 1rem;
  border: 1px solid $gray-border;
  border-radius: .25rem;
  cursor: pointer;
  .add-more-img{
    background: $primary;
    min-width: 2.5rem;
    min-height: 2.5rem;
    flex: 0 0 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      font-size: 1.25rem;
      color: $white;
    }
  }
}
// end add more

// data-table
.rdt_Table{
  .rdt_TableHeadRow{
    min-height: 2.5rem;  
    border: 1px solid #EDF2F7;
    border-radius: .625rem;
    margin-bottom: 1rem;
  }
  .rdt_TableRow{
    min-height: 4rem;  
    border: 1px solid #EDF2F7!important;
    border-radius: .625rem;
    margin-bottom: 1rem;
    .rdt_TableCell{
      div:first-child{
        white-space: nowrap;
        overflow: unset;
        text-overflow: ellipsis;
        min-width: 0px;
      }
      [data-tag="allowRowEvents"]{
        flex: 1;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.5rem;
        color: $black;
      }
    }
  }
}

// end data-table

// revoke access
.revoke-access{
  cursor: pointer;
}
//end revoke access

// medicine
.medicine-tile{
  border: 1px solid $gray-border;
  border-radius: .625rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  .user-text{
    min-width: 0px;
  }
  .user-textSubHeading{
    min-width: 0px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  }
// end medicine

// order and kits
.orderKits-wrap{
  .user-text{
    .user-textHeading {
      margin-bottom: .125rem;
    }
    .user-textSubHeading {
      &:not(:last-child){
        margin-bottom: .125rem;
      }
    }
  }
}
// end order and kits

// subscrition
.subscription-wrap{
  margin-top: 2.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include mx-767{
    flex-wrap: wrap;
  }
  .subscriptionInfo{
    max-width: 30rem;
    width: 100%;
    @include mx-767{
      flex-wrap: wrap;
      max-width: 100%;
    }
  }
  .subscription-total{
    max-width: 24.75rem;
    width: 100%;
    @include mx-767{
      max-width: 100%;
    }
  }
  .subscriptionInfo-tile{
 
    border: 1px solid $light-gray;
    border-radius: .25rem;
  } 
  .subscriptionInfo-body{
    padding: .75rem;
  }
 .subscriptionInfo-footer{
   border-top: 1px solid $gray-border;

 }
}
.subscriptionTotal-inner{
  padding: 16px;
  border: 1px solid $light-gray;
  border-radius: .25rem;
  max-width: 24.0625rem;
  width: 100%;
  .icon-times {
    font-size: 1rem;
    color: $danger-dark;
    cursor: pointer;
  }
  .user-block{
    margin-bottom:1rem ;
    border-bottom: 1px solid $light-gray;
    padding-bottom:1rem ;
  }
}
// end subscrition 

// email invition
.email-main-subheading{
  max-width: 692px;
}
// end email invition

// inner-step
.step-section{

  min-height: 100%;
    height: 100vh;
    position: fixed;
    right: 0px;
    top: 0px;
    padding: 0px 0px;
    max-width: 700px;
    transition: all 0.2s;
    z-index: 10;
    background: #FFFFFF;
    box-shadow: -4px 0px 40px 20px rgba(0, 0, 0, 0.1);
    width: calc(100% - 20px);
    .progress-baar {
      background: $gray-border;
      width: 100%;
      height: .5rem;
      .progress-baar-value {
          background: $primary;
          height: 100%;
      }
  }
    &>div, .rsw_2Y, .rsw_2f, .step-inner-wrap{
      height: 100%;
    }
    .step-inner-wrap{
      display: flex;
      flex-flow: column;
      .step-header{
        flex:0 0;
      }
      .step-body{
        flex: 1 1 ;
        overflow: auto;
      }
    }

  .step-header{
    .stepBtn-group{
  
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1rem 4rem;
      @include mx-767{
        padding: 0.5rem 2rem;  
      }
    }
    .stepBack-btn{
     text-decoration: underline;
     margin-right: 2rem;
     cursor: pointer;
    }
  }
  .step-body{
  padding: 3.375rem 4rem 1rem 3.375rem;
  .step-heading {
    margin-bottom: 2.5rem;
    @include mx-767{
      margin-bottom: 1rem;  
    }
  }
  .step-form{
    max-width: 23rem;
  }
    @include mx-767{
      padding: 4rem 2rem;  
    }
  }
  .step-footer{
    // position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    box-shadow: 0px -4px 4px -1px rgba(26, 32, 44, 0.04), 0px -2px 6px -1px rgba(26, 32, 44, 0.1);
    padding: .8125rem;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.75rem; 
    z-index: 7;
  }
}


// end - inner-step

// number list
.number-list{
  li{
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
}
// end number list
