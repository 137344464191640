// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 700;
    font-family: $font-family-base;
}

h1,
.h1 {
    font-size: 1rem;
    line-height: size(24);
}

h2,
.h2 {
    font-size: 1.875rem;
    line-height: size(48);
}

h3,
.h3 {
    font-size: 1.5rem;
    line-height: size(32);
}

h4,
.h4 {
    font-size: 1.25rem;
    line-height: size(24);
}

h5,
.h5 {
    font-size: 1.125rem;
    line-height: size(24);
}

h6,
.h6 {
    font-size: 1.125rem;
    line-height: size(24);
}

.lg-text,
.xl-text,
.sm-text {
    font-style: normal;
    font-family: $font-family-base;
    line-height: 1.5rem;
}

.xl-text {
    font-size: size(24);
}
.lg-text {
    font-size: size(16);
}

.sm-text {
    font-size: size(14);
    line-height: size(24);
}

.xs-text {
    font-size: size(11);
    line-height: size(11);
}
.underline-anchor {
    color: inherit;
    border-bottom: 1px solid ;
}

.b-400{
    font-weight: 400;
}
.b-500{
    font-weight: 500;
}
.b-600{
    font-weight: 600;
}
.b-700{
    font-weight: 700;
}
.b-800{
    font-weight: 800;
}
.b-900{
    font-weight: 900;
}

// default text color
.primary-text{
    color: $primary;
}
.success-dark-text{
    color: $success-dark;
}
.white-text{
    color: $white;
}
.black-text{
    color: $black;
}
.light-black-text{
    color: $light-black;
}
.grey-text{
    color: $dark;
}



// default bg color
.primary-bg{
    background: $primary;
}
.warning-light-bg{
    background: $warning-light;
}
.white-bg{
    background: $white;
}
.black-bg{
    background: $black;
}
.light-black-bg{
    background: $light-black;
}
.grey-bg{
    background: $dark;
}
.success-light-bg{
    background: $success-light;
}
.green-bg{
    background: $green;
}
.dark-gray-bg{
    background: $dark-gray;
}
.danger-dark-bg{
    background: $danger-dark;
}
.success-light-bg{
    background: $success-light;
}
.success-lightest-bg{
    background: $success-lightest;
}

.latter-spacing{
    letter-spacing: .225rem;
}