

/* loader */
.loader-wrap{
    position: fixed;
    background: #fff;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    // display: none;
}
.loader-block{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-flow: column;   
}
.loader-block img{
    width: 2.5rem;
    margin: 0  auto;
    animation: spin 1.5s linear infinite;
}

.loader-text{
    margin-top: 1.25rem;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.5rem;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
/* end loader */