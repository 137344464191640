.blacklayer-sidebar{
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 8;
    position: fixed;
    &.show{
        display: block;
    }
    &.hide{
        display: none;
    }
}
.dashboard-layout-wrap{
    .sidebar-block{
    min-height: 100%;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    padding-top: 5rem;
    width: 13.75rem;
    background: #001CD7;
    transition: all 0.2s;
    z-index: 9;
    .sidebar-text{
        white-space: nowrap;
        // min-width: 0px;
        // overflow: hidden;
        // text-overflow: ellipsis;
       
    }
    .accordion-body{
        padding: 1rem 0rem 1rem 0.5rem;
    }
    .accordion-header{
        min-width: 0px;
    }
    .accordion-item{
        background: transparent;
      box-shadow: none;
      border: none;
    }
    .accordion-button{
        background: transparent;
        padding: 0px;
        color: #fff;
        box-shadow: none;
        height: auto;
        line-height: normal;
        position: static;
        &::before, &::after{
            display: none;
        }
        .sidebar-text{
            padding-right: 30px;
            user-select: none;
        }
        &:hover{
            .sidebar-text{
                font-weight: bold;
            }   
        }
        &.active{
            .sidebar-text{
                font-weight: bold;
                
            }   
        }
        
    }
    @include mx-991{
        transform: translate(-100%, 0px);

        &.sidebar-show{
            transform: translate(0%, 0px); 
        }
    }
    
    .nav{
        overflow: auto;
        flex-flow: column;
        padding: 4.5rem 1rem 1rem 1rem;
        @include mx-991{
            padding: 2rem 1rem 1rem 1rem;
        }
        .accordion-item {
            position: relative;
            .accordion-header{
                top: 13px;
                position: absolute;
                right: 22px;
            }
            .icon-angle-down{
              
                font-size: 21px;
                color: #fff;
            }
        }
        .accordion-item.active {
            // .collapse:not(.show){
            //     display: block;
            // }
            &>.nav-link{
                font-weight: 700;
                background: rgba(255, 255, 255, 0.2);
                border-radius: .25rem;
                border-right: .25rem solid $white;
                &.active{
                } 
            }
        }
        .nav-link{
            color: $white;
            padding: .625rem .9375rem .625rem .5rem;
            font-size: .875rem;
            line-height: 1.5rem;
            display: flex;
            position: relative;
            align-items: center;
            [class*="icon-"]{
                font-size: 1.25rem;
                padding-right:1.125rem ;
                &.icon-angle-down{
                    padding: 0px;
                    position: absolute;
                    right: 18px;
                  }
            }
            &.active{
                font-weight: 700;
                background: rgba(255, 255, 255, 0.2);
                border-radius: .25rem;
                border-right: .25rem solid $white;
            }
            &:hover{
                font-weight: 700;
                // background: rgba(255, 255, 255, 0.2);
                // border-radius: .25rem;
                // border-right: .25rem solid $white;
            }

        }
    }
  
    }
    .dashboard-content{
        margin-left: 220px;
        min-height: calc(100vh - 80px);
        overflow: auto;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        @include mx-991{
            margin-left: 0px;
            min-height: calc(100vh - 80px);
        }
    }
    .dashboard-inner-content{
        max-width: 63.375rem;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin: 0 auto;
        width: 100%;
        flex: 1 1 100%;
    }
   
}